<script>
export default {
  name: "TrainingSectionsBlock",
  components: {

  },
  data() {
    return {
      trainings: [
        {
          id: 1,
          imgUrl: require("@/assets/image/training/training-1.png"),
          head: "Трековые",
          link: "/training-post",
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/training/training-2.png"),
          head: "Светодиодные ленты",
          link: "/training-post",
        },
        {
          id: 3,
          imgUrl: require("@/assets/image/training/training-3.png"),
          head: "Подвесные",
          link: "/training-post",
        },
        {
          id: 4,
          imgUrl: require("@/assets/image/training/training-4.png"),
          head: "Накладные",
          link: "/training-post",
        },
        {
          id: 5,
          imgUrl: require("@/assets/image/training/training-5.png"),
          head: "Встраиваемые",
          link: "/training-post",
        },
        {
          id: 6,
          imgUrl: require("@/assets/image/training/training-6.png"),
          head: "Настольные",
          link: "/training-post",
        },
        {
          id: 7,
          imgUrl: require("@/assets/image/training/training-7.png"),
          head: "Уличные",
          link: "/training-post",
        },
      ],
    };
  },
};
</script>
<template>
  <section class="sections">
    <div class="container">
      <h2>Разделы обучения</h2>
      <div class="sections__content">
        <a 
            class="sections__item"
            v-for="training in trainings"
            :key="training.id"
            :href="training.link"
          >
            <img :src="training.imgUrl" :alt="training.head" />
            <h3>
              <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="path-arrow" d="M15 4.33203L19.6667 8.9987L15 13.6654" stroke="#111111" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                <path class="path-stroke" d="M19 9L3 9" stroke="#111111" stroke-width="1.6"/>
              </svg>
              {{ training.head }}
            </h3>
        </a>
      </div>
    </div>
  </section>
</template>
<style scoped>
.sections {
  color: #111111;
}
.sections h2 {
  font-family: Suisse Intl;
  font-size: 64px;
  font-weight: 450;
  line-height: 64px;
  margin-bottom: 52px;
}
.sections__content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
}
.sections__item {
  background: #EAEAEA;
  min-height: 292px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  color: #111111;
  overflow: hidden;
}
.sections__item svg {
  transition: .3s all;
  overflow: hidden;
  margin-left: -15px;
}
.sections__item .path-stroke {
  transform: translate(-25px, 0);
  transition: .3s all;
}
.sections__item:hover svg {
  margin-left: 0;
}
.sections__item:hover .path-stroke {
  opacity: 1;
  transform: translate(0, 0);
}
.sections__item:hover img {
  transform: scale(1);
}
.sections__item h3 {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: Suisse Intl;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.84px;
}
.sections__item img {
  position: absolute;
  top: 0;
  left: 0;
  transition: .3s all;
  transform: scale(1.05);
}
.sections__item:nth-child(1) {
  grid-column: span 3;
}
.sections__item:nth-child(2) {
  grid-column: span 5;
}
.sections__item:nth-child(3) {
  grid-column: span 4;
}
.sections__item:nth-child(3) img {
  left: 30px;
}
.sections__item:nth-child(4) {
  grid-column: span 6;
  grid-row: 2 / 4;
}
.sections__item:nth-child(4) img {
  left: 40px;
}
.sections__item:nth-child(5), .sections__item:nth-child(6) {
  grid-column: span 3;
}
.sections__item:nth-child(5) img {
  left: 20px;
}
.sections__item:nth-child(6) img {
  top: auto;
  bottom: 0;
}
.sections__item:nth-child(7) {
  grid-column: 7 / 13;
}
.sections__item:nth-child(7) img {
  top: auto;
  bottom: 0;
}

@media (max-width: 1440px) {
  .sections__item:nth-child(3) img {
    left: 0;
  }
  .sections__item:nth-child(4) img {
    left: 0px;
  }
  .sections__item:nth-child(5) img {
    left: 0px;
  }  
}
@media (max-width: 1200px) {
  .sections__item {
    min-height: 222px;
  }
}
@media (max-width: 768px) {
  .sections h2 {
    margin-bottom: 34px;
    font-size: 58px;
    line-height: 100%;
  }
  .sections__content {
    gap: 12px;
  }
  .sections__item {
    min-height: 164px;
  }
  .sections__item h3 {
    font-size: 13px;
    line-height: 15px;
    gap: 6px;
    padding: 12px;
  }
}
@media (max-width: 575px) {
  .sections h2 {
    font-size: 33px;
  }
  .sections__item {
    overflow: hidden;
  }
  .sections__item:nth-child(1) {
    grid-column: span 6;
  }
  .sections__item:nth-child(2) {
    grid-column: span 6;
  }
  .sections__item:nth-child(3) {
    grid-column: 1 / 7;
  }
  .sections__item:nth-child(4) {
    grid-column: 1 / 13;
  }
  .sections__item:nth-child(4) img {
    width: 50%;
    left: 25%;
  }
  .sections__item:nth-child(5) {
    grid-column: span 6;
  }
  .sections__item:nth-child(6) {
    grid-column: span 6;
  }
  .sections__item:nth-child(7) img {
    left: -100px;
    max-width: 350px;
  }
}
</style>