<script>
import HeaderBlock from "../block/HeaderBlock.vue";
import FooterBlock from "../block/FooterBlock.vue";
import TrainingContentBlock from "../block/TrainingContentBlock.vue";
import BreadcrumbsUi from "../ui/Breadcrumbs.vue";

export default {
    name: "TrainingPostPage",
    components: {
        HeaderBlock,
        BreadcrumbsUi,
        TrainingContentBlock,
        FooterBlock
    },
}
</script>

<template>
    <HeaderBlock :className="'white'"></HeaderBlock>
    <BreadcrumbsUi></BreadcrumbsUi>
    <TrainingContentBlock></TrainingContentBlock>
    <FooterBlock></FooterBlock>
</template>