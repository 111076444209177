<script>
export default {
  name: "FooterBlock",
  components: {},
  data() {
    return {
      links: [
        {
          id: 1,
          name: "Компания",
          link: "#",
          subLinks: [
            {
              id: 1,
              name: "Новости",
              link: "#",
            },
            {
              id: 2,
              name: "Обучение",
              link: "#",
            },
            {
              id: 3,
              name: "О компании",
              link: "#",
            },
          ],
        },
        {
          id: 2,
          name: "Каталог",
          link: "#",
          subLinks: [
            {
              id: 1,
              name: "Трековые",
              link: "#",
            },
            {
              id: 2,
              name: "Подвесные",
              link: "#",
            },
            {
              id: 3,
              name: "Накладные",
              link: "#",
            },
            {
              id: 4,
              name: "Встраиваемые",
              link: "#",
            },
            {
              id: 5,
              name: "Светодиодные ленты",
              link: "#",
            },
            {
              id: 6,
              name: "Блоки питания",
              link: "#",
            },
          ],
        },
        {
          id: 3,
          name: "Контакты",
          link: "#",
          subLinks: [
            {
              id: 1,
              name: "8 (928) 422-12-21",
              link: "tel:+84723384221",
            },
            {
              id: 2,
              name: "info@lumatica.ru",
              link: "mailto:info@lumatica.ru",
            },
            {
              id: 3,
              name: "@lumatica.ru",
              link: "https://lumatica.ru",
            },
          ],
        },
      ],
    };
  },
};
</script>

<template>
  <div class="sector">
    <div class="footer">
      <div class="container-footer">
        <svg
          class="triangle"
          width="56.000000"
          height="54.000000"
          viewBox="0 0 56 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <defs />
          <path
            id="Vector"
            d="M56 0L56 54L54.5607 52.6092L53.6722 51.7562L38.5505 37.1714L14.6242 14.1027L1.67921 1.62259L0.71965 0.695404L0 0L56 0Z"
            fill="#FFFFFF"
            fill-opacity="1.000000"
            fill-rule="nonzero"
          />
        </svg>
        <img
          class="topVector absoluteVector"
          src="@/assets/image/Top (Desktop HD).svg"
          alt=""
        />
        <img
          class="leftVector absoluteVector"
          src="@/assets/image/Left (Desktop HD).svg"
          alt=""
        />
        <img
          class="rightVector absoluteVector"
          src="@/assets/image/Right (Desktop HD).svg"
          alt=""
        />
        <img
          class="bottomVector absoluteVector"
          src="@/assets/image/Bottom (Desktop HD).svg"
          alt=""
        />
        <div class="footer__content">
          <div class="logo">
            <img class="logo-image" src="@/assets/image/logo.svg" alt="" />
            <div class="footer__date">2024</div>
          </div>
          <div class="nav-links">
            <div class="col" v-for="link in links" :key="link.id">
              <a :href="link.link" class="boss__link">{{ link.name }}</a>
              <div class="sublinks">
                <a
                  v-for="sublink in link.subLinks"
                  :key="sublink.id"
                  :href="sublink.link"
                  class="sublink"
                  >{{ sublink.name }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer {
  background: var(--Black-1300, #1d1d1b);
  height: 367px;
  padding: 30px;
}
.footer__content {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.logo {
  display: flex;
  align-items: flex-end;
  gap: 54px;
  align-self: flex-end;
}
.logo-image {
  transform: translateY(8px);
}
.nav-links {
  display: flex;
  gap: 116px;
}
.col {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.boss__link {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sublinks {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.sublink {
  color: var(--Black-400, #d9d9d9);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer__date {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 17px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.triangle {
  position: absolute;
  top: 0;
  right: 0;
}
.container-footer {
  max-width: 1860px;
  padding: 43px 186px 7px 0px;
  height: 100%;
  margin: 0 auto;
  position: relative;
}
.absoluteVector {
  position: absolute;
  z-index: 2;
  object-fit: cover;
}
.topVector {
  top: 0;
  left: 0px;
  max-width: calc(100% - 99px);
  height: 1px;
}
.leftVector {
  top: 0;
  left: 0;
  max-height: calc(100% - 146.24px);
  width: 1px;
}
.rightVector {
  bottom: 0;
  right: 0;
  max-height: calc(100% - 96.5px);
  width: 1px;
}
.bottomVector {
  bottom: 0;
  right: 0;
  max-width: calc(100% - 623.77px);
  height: 1px;
}
@media screen and (max-width: 1690px) {
  .nav-links{
    gap: 75px;
  }
}
@media (max-width: 1440px) {
  .container-footer {
    padding: 43px 100px 7px 0px;
  }
  .logo-image {
    width: 494px;
  }
  .footer__date {
    font-size: 14px;
  }
  .bottomVector {
    max-width: calc(100% - 547.77px);
    height: 1px;
  }
}
@media (max-width: 1024px) {
  .head-h1 svg {
    width: 283px;
    height: 47.56px;
  }

  .head-h1 {
    transform: translate(0px, 0px);
  }
  .container {
    padding: 20px;
  }
  .contacts {
    gap: 16px;
  }

  .nav-links {
    gap: 44px;
  }
  .container-footer {
    padding: 27px 20px 7px 0px;
  }
  .boss__link {
    font-size: 14px;
  }
  .sublink {
    font-size: 13px;
  }
  .logo-image {
    width: 253px;
  }
  .footer__date {
    font-size: 7.9px;
  }
  .logo {
    gap: 24px;
  }
  .bottomVector {
    max-width: calc(100% - 275.77px);
  }
  .footer {
    height: 278px;
  }
}
@media screen and (max-width: 650px) {
  .footer__content {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .logo {
    align-self: flex-start;
  }
  .col:nth-of-type(2) {
    order: 1;
    width: 100%;
  }
  .col:nth-of-type(1) {
    order: 2;
    width: calc(50% - 5px);
  }
  .col:nth-of-type(3) {
    order: 3;
    width: calc(50% - 5px);
  }
  .nav-links {
    flex-wrap: wrap;
    gap: 50px 10px;
    justify-content: space-between;
  }
  .footer {
    height: auto;
    padding: 17.52px 10px;
  }
  .col:nth-of-type(2) .sublinks {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .col:nth-of-type(2) .sublink {
    width: calc(50% - 5px);
  }
  .container-footer {
    padding: 40px 18px 0;
  }
  .logo-image {
    transform: translate(-17px, 1px);
  }
  .leftVector{
    max-height: calc(100% - 58.24px);
  }
  .footer__date{
    display: none;
  }
  .boss__link{
    font-size: 24px;
  }
  .sublink{
    font-size: 18px;
  }
}
</style>