<script>
export default {
  name: "ProductMini",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  mounted() {
    console.log(this.product);
  },
};
</script>

<template>
  <a class="product-mini">
    <router-link
      :to="{ name: 'ProductPage', params: { id: product.id } }"
      class="product__img"
    >
      <img
        :src="apiDomain + '/web/uploads/products/' + product?.productPhoto[0]"
      />
      <div class="tag" v-if="product.tag">{{ product.tag }}</div>
      <!-- <div class="sale" v-if="product.sale">{{ product.sale }}</div> -->
    </router-link>
    <div class="product__bot">
      <div class="product__bot-left">
        <div class="name">
          {{ product.name }}
        </div>
        <div class="price">
          <div class="normal-price">{{ product.price }} ₽</div>
          <div class="old-price" v-if="product.oldPrice">
            {{ product.oldPrice }} ₽
          </div>
        </div>
      </div>
      <div class="designer_cashback">
        {{ product.cashbak }}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
            fill="white"
          />
          <path
            d="M9.01908 11.8731V12.9959H12.1955V14.2097H9.01908V16H7.20191V14.2097H6V12.9959H7.20191V11.8731H6V10.28H7.20191V5H10.5215C13.1542 5 15 5.69793 15 8.42897C15 11.0386 13.1542 11.8731 10.5215 11.8731H9.01908ZM9.01908 10.28H10.593C11.9952 10.28 13.1256 9.97655 13.1256 8.42897C13.1256 6.89655 11.9952 6.60828 10.593 6.60828H9.01908V10.28Z"
            fill="#E07031"
          />
        </svg>
      </div>
    </div>
  </a>
</template>

<style scoped>
.product-mini {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  max-width: 365px;
}
.product-mini:hover img {
  transition: all 0.3s;
  transform: scale(1);
}
.product__img {
  position: relative;
  height: 360px;
  background: var(--Black-300, #eaeaea);
  overflow: hidden;
  padding: 8px;
}
.product-mini img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: all 0.3s;
}
.tag {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 10px;
  border: 1px solid var(--Black-1000, #545454);
  position: relative;
  z-index: 1;
  height: max-content;
  width: max-content;
}
.sale {
  background: url(@/assets/image/home/bg-tag.svg) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 26px 4px 10px;
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.name {
  font-family: "Suisse Intl";
  font-size: 15px;
  font-weight: 400;
  line-height: 17.37px;
  text-align: left;
  color: #111;
  margin-bottom: 4px;
}
.price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.normal-price {
  font-family: "Suisse Intl";
  font-size: 17px;
  font-weight: 500;
  line-height: 19.69px;
  text-align: left;
  color: #111;
}
.old-price {
  font-family: "Suisse Intl";
  font-size: 12px;
  font-weight: 450;
  line-height: 13.9px;
  text-align: left;
  color: #757575;
  text-decoration: line-through;
}
.product__bot {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media screen and (max-width: 1440px) {
  .product-mini{
    max-width: 305px;
  }
  .product__img{
    height: 300px;
  }
}
@media screen and (max-width: 1024px) {
  .product__img{
    height: 228px;
  }
  .name{
    font-size: 14px;
  }
  .normal-price{
    font-size: 16px;
  }
}
</style>