<script>
import HeaderBlock from "../block/HeaderBlock.vue";
import FooterBlock from "../block/FooterBlock.vue";
import PostContentBlock from "../block/PostContentBlock.vue";


 export default {
   name: "PostPage",
   components: {
     HeaderBlock,
     FooterBlock,
     PostContentBlock
   }
 }
</script>

<template>
    <HeaderBlock :className="'white'"></HeaderBlock>

    <PostContentBlock></PostContentBlock>
    
    <FooterBlock></FooterBlock>
</template>