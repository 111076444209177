<script>
export default {
  name: "BtnLink",
  props: {
    link: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      showNotification: false,
    };
  },
  
};
</script>

<template>
  <div>
    <a :href="link" :class="'btnLink ' + className">
      <slot></slot>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M5.8335 5.83301H14.1668M14.1668 5.83301V14.1663M14.1668 5.83301L5.8335 14.1663"
          stroke="#111111"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </a>
  </div>
</template>

<style>
.btnLink {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5.5px 0;
  cursor: pointer;
  position: relative;
}
.btnLink:before{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: var(--Black, #111);
  transition: all 0.3s;
  transition-delay: 0.2s;
}
.btnLink::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 1px;
  background: var(--Black, #111);
  transition: all 0.3s;
}
.btnLink:hover::before {
  width: 0%;
  transition: all 0.3s;
}
.btnLink:hover::after {
  width: 100%;
  transition: all 0.3s;
  transition-delay: 0.2s;
}
.about.btnLink {
  color: #fff;
  border-color: #fff;
}
.about.btnLink path {
  stroke: #fff;
}
@media screen and (max-width: 1024px) {
  .btnLink {
    font-size: 14px;
  }
  
}
</style>