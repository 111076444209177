<script>
export default {
  name: "PopupAdd",
  props: {
    msg: String,
  },
  data() {
    return {
        maxLength: 36
    }
    
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    checkSymbol(event) {
      let input = event.target;
      let inputLength = input.value.length;
      let number = document.querySelector(".checkSymbol .number");
      let btn = document.querySelector(".popup__body .btn-orange");
      if (inputLength !== 0) {
          btn.classList.remove("default");
      } else {
          btn.classList.add("default");
      }
      // Обновление количества символов
      number.innerHTML = inputLength;
      
      // Проверка длины ввода и ограничение символов
      if (inputLength > this.maxLength) {
        input.value = input.value.substring(0, this.maxLength);
        number.innerHTML = this.maxLength;
      }
    },
    createProject(){
      let input = document.querySelector(".input--popup");
      this.$emit("createProject", input.value);
    }
  },
};
</script>

<template>
  <div class="popup">
    <div class="popup__content">
      <a @click="$emit('closePopup')" class="popup__close">
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
            fill="#333333"
          />
        </svg>
      </a>
      <div class="popup__head">
        <h3 class="popup__title">Новый проект</h3>
      </div>
      <div class="popup__body">
        <div class="label-popup">Введите название проекта</div>
        <div class="input-wrap">
          <div class="checkSymbol"><span class="number">0</span>/36</div>
          <input @input="checkSymbol" type="text" class="input input--popup" />
        </div>
        <a @click="$emit('closePopup'); createProject()" class="btn-orange default">Создать проект</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.input {
  border-radius: 2px;
  background: var(--Black-200, #f2f2f2);
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%; /* 19.2px */
  padding: 20.5px 16px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  width: 100%;
}
.input:hover {
  border-radius: 2px;
  background: var(--Black-300, #eaeaea);
  transition: all 0.3s ease;
}
input:focus {
  border-radius: 2px;
  border: 1px solid var(--Black-900, #757575);
  background: var(--Black-200, #f2f2f2);
}
.popup__content {
  border-radius: 2px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(21, 30, 45, 0.05);
  padding: 32px 36px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  position: relative;
  width: 404px;
}
.popup__title {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.popup__head {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.popup__close {
  position: absolute;
  right: 2px;
  top: 2px;
}

.popup__body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.btn-orange {
  margin-top: 8px;
  width: 100%;
}
.label-popup {
  overflow: hidden;
  color: var(--Black, #111);
  text-overflow: ellipsis;
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input-wrap {
  position: relative;
  width: 100%;
}
.checkSymbol {
  position: absolute;
  right: 4px;
  top: 4px;
  color: var(--Black-900, #757575);
  text-align: right;
  font-family: "Suisse Intl";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 12px */
}
</style>