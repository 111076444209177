<script>
export default {
  name: "CategoriesBlock",
  components: {},
  data() {
    return {
      newsItems: [
        {
          id: 1,
          imgUrl: require("@/assets/image/home/news1.png"),
          head: "Открытие современного складского комлекса",
          date: "06.08.2022",
          description:
            "Наш новый складской комплекс — это не только площадка для хранения товаров, ",
          link: "/post",
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/home/news2.png"),
          head: "Встраиваемые",
          date: "06.08.2022",
          description:
            "Наш новый складской комплекс — это не только площадка для хранения товаров, ",
          link: "/post",
        },
        {
          id: 3,
          imgUrl: require("@/assets/image/home/news3.png"),
          head: "Подвесные",
          date: "06.08.2022",
          description:
            "Наш новый складской комплекс — это не только площадка для хранения товаров, ",
          link: "/post",
        },
        {
          id: 4,
          imgUrl: require("@/assets/image/home/news1.png"),
          head: "Открытие современного складского комлекса",
          date: "06.08.2022",
          description:
            "Наш новый складской комплекс — это не только площадка для хранения товаров, ",
          link: "/post",
        },
        {
          id: 5,
          imgUrl: require("@/assets/image/home/news2.png"),
          head: "Встраиваемые",
          date: "06.08.2022",
          description:
            "Наш новый складской комплекс — это не только площадка для хранения товаров, ",
          link: "/post",
        },
        {
          id: 6,
          imgUrl: require("@/assets/image/home/news3.png"),
          head: "Подвесные",
          date: "06.08.2022",
          description:
            "Наш новый складской комплекс — это не только площадка для хранения товаров, ",
          link: "/post",
        },
        {
          id: 7,
          imgUrl: require("@/assets/image/home/news3.png"),
          head: "Подвесные",
          date: "06.08.2022",
          description:
            "Наш новый складской комплекс — это не только площадка для хранения товаров, ",
          link: "/post",
        }
      ],
    };
  },
};
</script>

<template>
  <div class="categoryes">
    <div class="container">
      <div class="categoryes__content">
        <div class="products-kategories__head">
          <div class="title">Новости</div>
        </div>
        <div class="news__items">
          <a
            class="news__item"
            v-for="news in newsItems"
            :key="news.id"
            :href="news.link"
          >
            <div class="news__img">
              <img :src="news.imgUrl" :alt="news.head" />
            </div>
            <div class="news__attributes">
              <div class="date">{{ news.date }}</div>
              <div class="head-news">{{ news.head }}</div>
              <div class="excerpt">{{ news.description }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.categoryes__content {
  display: flex;
  flex-direction: column;
  gap: 54px;
}
.products-kategories__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news__items {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 42px 20px;
}
.news__item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-column: span 4;
  gap: 14px;
}
.news__item:nth-of-type(1),
.news__item:nth-of-type(7),
.news__item:nth-of-type(12) {
  grid-column: span 5;
}
.news__item:nth-of-type(2),
.news__item:nth-of-type(6),
.news__item:nth-of-type(13) {
  grid-column: span 7;
}
.news__img {
  position: relative;
  overflow: hidden;
  width:100%;
  height: 372px;
}
.news__item img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  left: 0;
  right: 0;
  transition: all 0.3s;
  align-self: center;
}
.news__item:hover img {
  transform: scale(1.1);
}
.news__attributes {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.head-h4 {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 6px;
}
.head-news {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 4px;
}
.date {
  color: var(--Black-900, #757575);
  font-family: "Suisse Intl";
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.excerpt {
  overflow: hidden;
  color: var(--Black-1100, #434343);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1024px) {
  .news__img {
    height: 228px;
  }
  .news__items {
    gap: 30px 12px;
  }
  .date{
    font-size: 12px;
  }
  .head-news{
    font-size: 15px;
  }
  .excerpt{
    font-size: 12px;
  }
  .categoryes__content {
    gap: 36px;
  }
}
@media screen and (max-width: 650px) {
  .categoryes__content {
    gap: 24px;
  }
  .date{
    font-size: 10px;
  }
  .head-news{
    font-size: 12px;
  }
  .excerpt{
    font-size: 10px;
  }
  .news__item{
    gap: 10px;
  }
  .news__attributes{
    gap: 4px;
  }
  .news__img {
    height: 162px;
  }
  .news__items{
    grid-template-columns: repeat(2, 1fr);
  }
  .news__item:nth-of-type(1), .news__item:nth-of-type(7), .news__item:nth-of-type(12), .news__item:nth-of-type(2), .news__item:nth-of-type(6), .news__item:nth-of-type(13), .news__item{
    grid-column: auto;
  }
  .news__items {
    gap: 26px 12px;
  }
}
</style>