<script>
export default {
  name: "HomeNewProducts",
  components: {},
  data() {
    return {
      products: [
        {
          id: 1,
          imgUrl: require("../../assets/image/home/new-product.png"),
          name: "xtd-ww-10-20w",
        },
        {
          id: 2,
          imgUrl: require("../../assets/image/home/new-product2.png"),
          name: "xtd-a1-05-10w",
        },
        {
          id: 3,
          imgUrl: require("../../assets/image/home/new-product3.png"),
          name: "xtd-b-05-12w",
        },
        {
          id: 4,
          imgUrl: require("../../assets/image/home/new-product4.png"),
          name: "tsd003x-3f-5w",
        },
        {
          id: 5,
          imgUrl: require("../../assets/image/home/new-product5.png"),
          name: "xtd-a1-01-2w",
          class: "big",
        },
        {
          id: 6,
          imgUrl: require("../../assets/image/home/new-product6.png"),
          name: "xtd-a-03-6w",
        },
        {
          id: 7,
          imgUrl: require("../../assets/image/home/new-product7.png"),
          name: "nmw-tsd-75-12w",
        },
        {
          id: 8,
          imgUrl: require("../../assets/image/home/new-product8.png"),
          name: "nmw-gsd-s75-7w",
        },
        {
          id: 9,
          imgUrl: require("../../assets/image/home/new-product9.png"),
          name: "xtd5-09-18w",
        },
      ],
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    // Слушаем изменения ширины окна
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    // Удаляем слушатель при уничтожении компонента
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    filteredProducts() {
      if (this.windowWidth < 650) {
        return this.products.slice(0, 5);
      }
      if (this.windowWidth < 1024) {
        return this.products.slice(0, 6);
      }
      return this.products;
    },
  },
};
</script>

<template>
  <div class="new-products sector">
    <div class="container">
      <div class="new-products__content">
        <div class="new-products__head">
          <div class="head-h3">Новинки</div>
        </div>
        <div class="new-products__items">
          <a
            class="new-products__item"
            v-for="product in filteredProducts"
            :key="product.id"
            :class="[product.class, { 'some-condition-class': product.class }]"
          >
            <div class="new-products__item-img">
              <img :src="product.imgUrl" :alt="product.name" />
              <div class="tag">NEW</div>
            </div>
            <div class="head-h4">
              {{ product.name }}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.new-products__content {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.new-products__items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.new-products__item {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.new-products__item.big {
  grid-column: span 2;
  grid-row: 2 / 4;
}
.new-products__item:hover img {
  transition: all 0.3s;
  transform: scale(1);
}
.new-products__item-img {
  position: relative;
  min-height: 360px;
  background: var(--Black-300, #eaeaea);
  overflow: hidden;
  padding: 8px;
  height: 100%;
}
.new-products__item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: all 0.3s;
}
.tag {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 10px;
  border: 1px solid var(--Black-1000, #545454);
  position: relative;
  z-index: 1;
  height: max-content;
  width: max-content;
}
.head-h4 {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1440px) {
  .new-products__item-img {
    min-height: 301px;
  }
}
@media screen and (max-width: 1024px) {
  .new-products__items {
    grid-template-columns: repeat(3, 1fr);
  }
  .new-products__item-img {
    min-height: 228px;
  }
}
@media screen and (max-width: 650px) {
  .new-products__items {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    grid-template-rows: repeat(4, 1fr);
  }
  .new-products__item {
    gap: 8px;
  }
  .new-products__item-img {
    min-height: 163px;
  }
  .new-products__item.big{
    grid-row-start: 2;
    grid-row-end: 4;
  }
  .tag{
    font-size: 10px;
    padding: 5px 8px;
  }
}
</style>