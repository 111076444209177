<script>
export default {
  name: "TrainingSectionsBlock",
  components: {

  },
  data() {
    return {
      items: [
        {
          id: 1,
          imgUrl: require("@/assets/image/training/post-1.jpg"),
          head: "Яркость и потребление светильника",
          text: "Выбор освещения производится с учетом тщательных расчетов, которые проводит опытный специалист при помощи специализированного программного обеспечения. Профессионал учитывает назначение помещения, его особенности, а также соответствие нормам освещенности, установленным в строительных нормах и правилах (СНиП).",
          link: "#",
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/training/post-2.jpg"),
          head: "Цвет свечения",
          text: "Для жилых помещений подойдут светильники с температурой 2200-3000. Светильники с температурой в 3400-4500 больше подходят для коммерческих помещений. Светильники с температурой в 6500 и выше предназначены только для технических помещений. Подбор светильников с учетом цветовой температуры играет ключевую роль в создании оптимальной атмосферы.",
          link: "#",
        },
        {
          id: 3,
          imgUrl: require("@/assets/image/training/post-3.jpg"),
          head: "Класс IP защиты",
          text: "Выбор степени защиты особенно важен для влажных и пыльных помещений. В этом случае подходят модели класса IP44 и выше. Для сухих и малозапыленных помещений достаточно IP20. На улице необходимо применять устройства уровня IP67/IP68. Все эти меры по выбору степени защиты помогают обеспечить безопасность эксплуатации светильников в соответствии с условиями конкретного помещения или места установки.",
          link: "#",
        },
        {
          id: 4,
          imgUrl: require("@/assets/image/training/post-4.jpg"),
          head: "Управление",
          text: "Управление с дистанционного пульта позволяет включать и выключать свет, регулировать яркость, настраивать цвет и температуру свечения. Модели могут иметь различный функционал управления. Таким образом, функционал управления освещением с дистанционного пульта становится важным элементом современных систем освещения, обеспечивая удобство и гибкость в использовании.",
          link: "#",
        },
      ],
    };
  },
};
</script>

<template>
  <section class="postUp">
    <div class="container">
      <div class="postUp__title">
        <h2>Руководство по выбору накладных светильников</h2>
        <p>На сегодняшний день существует масса осветительных приборов, которые могут использоваться в качестве основного или дополнительного источника освещения. Производители предлагают самые различные варианты, начиная от традиционных приборов с плафонами и заканчивая компактными, но весьма яркими светильниками с высокой светоотдачей. </p>
      </div>
      <div class="postUp__imgs">
        <img src="@/assets/image/training/postup-1.jpg" alt="">
        <img src="@/assets/image/training/postup-2.jpg" alt="">
      </div>
    </div>
  </section>
  <section class="post">
    <div class="container">
      <h2>Технические параметры для выбора подходящего светильника</h2>
      <div class="post__content">
        <div
            class="post__item"
            v-for="item in items"
            :key="item.id"
          >
            <div class="post__item-text">
              <h3>{{ item.head }}</h3>
              <p>{{ item.text }}</p>
              <a :href="item.link" class="btn">Перейти к покупкам
                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.83301 6.33301H14.1663M14.1663 6.33301V14.6663M14.1663 6.33301L5.83301 14.6663" stroke="#111111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </a>
            </div>
            <div class="post__item-img">
              <img :src="item.imgUrl" :alt="item.head" />
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.postUp {
  margin-bottom: 140px;
}
.postUp__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-bottom: 80px;
}
.postUp__title h2 {
  font-family: Suisse Intl;
  font-size: 66px;
  font-weight: 450;
  line-height: 76.43px;
  max-width: 840px;
  color: #111111;
}
.postUp__title p {
  max-width: 500px;
  color: #2E2E2E;
}
.postUp__imgs {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.postUp__imgs img:nth-child(1) {
  width: 41%;
}
.postUp__imgs img:nth-child(2) {
  width: 57.5%;
}

.post {
  color: #111111;
}
.post h2 {
  font-family: Suisse Intl;
  font-size: 56px;
  font-weight: 450;
  line-height: 64.85px;
  max-width: 1007px;
  margin-bottom: 90px;  
}
.post__content {
  gap: 140px;
  display: flex;
  flex-direction: column;
}
.post__item {
  display: flex;
  align-items: center;
  gap: 50px;
}
.post__item:nth-child(even) {
  flex-direction: row-reverse;
}
.post__item-text {
  max-width: 39%;
  flex-shrink: 0;
}
.post__item-img {
  width: 100%;
}
.post__item h3{
  margin-bottom: 16px;
  font-family: Suisse Intl;
  font-size: 28px;
  font-weight: 450;
  line-height: 32.42px;
}
.post__item p {
  margin-bottom: 28px;
  font-family: Suisse Intl;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.53px;
  color: #2E2E2E;
}
.btn {
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: Suisse Intl;
  font-size: 15px;
  font-weight: 450;
  line-height: 17.37px;
  color: #111111;
}
@media screen and (max-width: 1440px) {
  .postUp__title h2 {
    font-size: 56px;
    line-height: 64.85px;
  }
  .postUp__title p {
    font-size: 15px;
    line-height: 17.37px;
    max-width: 416px;
  }
}
@media screen and (max-width: 991px) {
  .postUp {
    margin-bottom: 120px;
  }
  .postUp__title {
    flex-direction: column;
    margin-bottom: 68px;
    gap: 32px;
  }
  .postUp__title h2 {
    max-width: 100%;
    font-family: Suisse Intl;
    font-size: 52px;
    line-height: 60.22px;
  }
  .postUp__title p {
    max-width: 100%;
  }
  .postUp__imgs {
    gap: 12px;
  }
  .postUp__imgs img:nth-child(1) {
    width: 33%;
  }
  .postUp__imgs img:nth-child(2) {
    width: 66%;
  }
  .post h2 {
    font-size: 34px;
    line-height: 39.37px;
    margin-bottom: 60px;
  }
  .post__content {
    gap: 76px;
  }
  .post__item {
    flex-direction: column;
    gap: 36px;
  }
  .post__item-text {
    max-width: 100%;
  }
  .post__item:nth-child(even) {
    flex-direction: column;
  }
  .post__item h3 {
    font-size: 24px;
    line-height: 27px;
    margin-bottom: 14px;
  }
  .post__item p {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 575px) {
  .postUp {
    margin-bottom: 70px;
  }
  .postUp__title {
    margin-bottom: 48px;
  }
  .postUp__title h2 {
    font-size: 36px;
    line-height: 40.22px;
  }
  .postUp__imgs img:nth-child(2) {
    width: 63%;
  }
  .post h2 {
    font-size: 26px;
    line-height: 31.37px;
    margin-bottom: 40px;
  }
}
</style>