<script>
import HeaderBlock from "../block/HeaderBlock.vue";
import FooterBlock from "../block/FooterBlock.vue";
import BreadcrumbsUi from "../ui/Breadcrumbs.vue";
import HomeActionBlockVue from "../block/HomeActionBlock.vue";
import NewsBlock from "../block/NewsBlock.vue";

export default {
  name: "CategoryesPage",
  components: {
    HeaderBlock,
    FooterBlock,
    BreadcrumbsUi,
    HomeActionBlockVue,
    NewsBlock
  },
};
</script>

<template>  
    <HeaderBlock :className="'white'"></HeaderBlock>
    <BreadcrumbsUi></BreadcrumbsUi>
    <NewsBlock></NewsBlock>
    <HomeActionBlockVue></HomeActionBlockVue>
    <FooterBlock></FooterBlock>
</template>