<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
export default {
  name: "PopupProduct",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      characteristics: [
        { id: 1, title: "Способ установки:", value: "Встраиваемый-поворотный" },
        { id: 2, title: "Форма:", value: "R" },
        { id: 3, title: "Угол свечения:", value: "13-40°" },
        { id: 4, title: "Гарантия:", value: "60 месяцев" },
        { id: 5, title: "Влагозащита:", value: "IP20" },
      ],
      modifications: [
        { id: 1, title: "Крепление:", value: "1PH Track" },
        { id: 2, title: "Мощность:", value: "6 Вт" },
        { id: 3, title: "Цветопередача:", value: "CRI 95+" },
        { id: 4, title: "Цветовая температура:", value: "2400 K" },
        { id: 5, title: "Угол свечения:", value: "15°" },
        { id: 6, title: "Цвет корпуса:", value: "Черный" },
        { id: 7, title: "Управление яркостью:", value: "Triac" },
      ],
      variations: [],
      selectedAttr: [],
      accessories: [
        {
          id: 1,
          title: "TRD-1M-0610-WT",
          article: "1704691",
          price: "5600",
          kat: "Шинопровод DALI 3 фазы",
        },
        {
          id: 2,
          title: "AC-MINI FOCUS-FRAM-WT",
          article: "2956787",
          price: "1200",
          kat: "Прижимное кольцо",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    // начальный активный индекс
    const onSwiper2 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper2,

      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  mounted() {
    console.log(this.product);
    this.sortAttributes(this.product.attribute);
  },
  methods: {
    sortAttributes(groups) {
      console.log("groupedAttributes", groups);
      const groupedAttributes = {};
      groups.forEach((item) => {
        if (item.attrGroup === "Производитель") {
          this.categoryName = item.attr;
          return;
        }
        if (!groupedAttributes[item.attrGroup]) {
          groupedAttributes[item.attrGroup] = [];
        }
        groupedAttributes[item.attrGroup].push({
          attr: item.attr,
          id: item.attrId,
        });
      });

      let idCounter = 1;

      const result = Object.keys(groupedAttributes).map((group, index) => {
        return {
          id: index + 1,
          title: group,
          inputs: groupedAttributes[group].map((attr) => ({
            id: idCounter++,
            title: attr,
          })),
        };
      });

      this.variations = result;
      console.log("Variations", this.variations);
    },
    isSelected(id) {
      return this.selectedAttr.includes(id);
    },
  },
};
</script>

<template>
  <div class="popup">
    <div class="popup-product__content">
      <div class="popup-product__image">
        <Swiper
          :slides-per-view="1"
          :space-between="0"
          :modules="modules"
          :pagination="true"
          @swiper="onSwiper2"
          class="mySwiper2"
        >
          <SwiperSlide v-for="image in product.productPhoto" :key="image">
            <img :src="apiDomain + '/web/uploads/products/' + image" alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
      <div class="popup-product__characteristics">
        <div class="head">Характеристики</div>
        <div class="product-about__items">
          <div
            v-for="char in characteristics"
            :key="char.id"
            class="product-about__item characteristics"
          >
            <div class="product-about__item--label">
              {{ char.title }}
            </div>
            <div class="product-about__item--value">
              {{ char.value }}
            </div>
          </div>
        </div>
      </div>
      <div class="btns">
        <a href="" class="btn-black">Добавить в проект</a>
        <a href="" class="btn-white">Перейти на страницу товара</a>
      </div>
      <div class="popup-product__info--wrapper">
        <div class="popup-product__info">
          <div class="popup-product__head">
            <div class="product__title">{{ product.name }}</div>
            <div class="product__category">{{ category }}</div>
          </div>
          <div class="status">
            {{ product.quantity > 0 ? "В наличии" : "Нет в наличии" }}
          </div>
        </div>
        <div class="popup-product__price">
          <div class="product__price head-22">{{ product.price }} ₽</div>
          <div class="designer_cashback">
            + 1 846
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                fill="white"
              />
              <path
                d="M9.01908 11.8731V12.9959H12.1955V14.2097H9.01908V16H7.20191V14.2097H6V12.9959H7.20191V11.8731H6V10.28H7.20191V5H10.5215C13.1542 5 15 5.69793 15 8.42897C15 11.0386 13.1542 11.8731 10.5215 11.8731H9.01908ZM9.01908 10.28H10.593C11.9952 10.28 13.1256 9.97655 13.1256 8.42897C13.1256 6.89655 11.9952 6.60828 10.593 6.60828H9.01908V10.28Z"
                fill="#E07031"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="modification">
        <div class="head-22">Модификации</div>
        <div class="product__variation">
          <div class="product__variations-inputs">
            <div
              class="variation"
              v-for="variation in variations"
              :key="variation.id"
            >
              <div class="variation__title">{{ variation.title }}</div>
              <div class="variation__inputs">
                <div
                  class="variation__input"
                  v-for="input in variation.inputs"
                  :key="input.id"
                >
                  <input
                    :id="input.title.id"
                    :name="'variation' + variation.title + variation.id"
                    :value="input.title.id"
                    :checked="isSelected(input.title.id)"
                    class="radio"
                    type="radio"
                  />
                  <label class="label" :for="input.title.id">
                    {{ input.title.attr }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="accesors">
        <div class="head-22">Аксессуары</div>
        <div class="product__accessories">
          <div
            class="product__accessory"
            v-for="accessory in accessories"
            :key="accessory.id"
          >
            <div class="product__accessory--image">
              <img src="@/assets/image/acces.png" alt="" />
            </div>
            <div class="product__accessory--title">
              <div class="article">Артикул: {{ accessory.article }}</div>
              <div class="name">{{ accessory.title }}</div>
              <div class="kat">{{ accessory.kat }}</div>
            </div>
            <div class="product__accessory--price">
              <div class="label-access">Стоимость</div>
              <div class="price">{{ accessory.price }} ₽</div>
            </div>
            <div class="btn-check">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.1665 10.0001H15.8332M9.99984 4.16675V15.8334"
                  stroke="white"
                  stroke-width="1.16667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.popup-product {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: all 0.3s;
}
.popup-product__content {
  border-radius: 2px;
  background: var(--White, #fff);
  padding: 12px 40px 12px 12px;
  display: grid;
  grid-template-columns: 374px 1fr;
  gap: 38px;
  width: 100%;
  max-width: 1165px;
  max-height: 100%;
  overflow-y: auto;
  max-height: 754px;
}

.popup-product__image {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 4;
}
.popup-product__characteristics {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 4;
  grid-row-end: 5;
}
.popup-product__info--wrapper {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
}
.btns {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
  grid-row-end: 6;
}
.modification {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 9;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.accesors {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 9;
  grid-row-end: 10;
}
.mySwiper2 {
  width: 374px;
  height: 292px;
}
.head {
  color: var(--black-p-process-black-c, #1d1d1b);
  font-family: "Suisse Intl";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding-left: 4px;
}
.product-about__items {
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 2px;
  background: var(--Black-125, #f7f7f7);
  padding: 16px;
}
.product-about__item {
  display: flex;
  align-items: center;
  gap: 15px;
}
.characteristics .product-about__item--label {
  width: 127px;
  flex-shrink: 0;
}
.product-about__item--label {
  color: #3f3f3f;
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.product-about__item--value {
  color: var(--black-p-process-black-c, #1d1d1b);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%;
}
.product__variations {
  display: flex;
  flex-direction: column;
  gap: 52px;
  padding-top: 140px;
}
.product__variation {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  padding: 11px 22px;
  border-top: 1px solid #a1a1a1;
  border-bottom: 1px solid #a1a1a1;
}
.product__variations-inputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.variation {
  display: flex;
  gap: 5px;
  align-items: center;
}
.variation__title {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%; /* 18px */
  width: 144px;
  flex-shrink: 0;
}
.variation__inputs {
  display: flex;
  gap: 4px;
}
.radio {
  display: none;
}
.label {
  border: 1px solid var(--Black-300, #eaeaea);
  background: var(--Black-150, #f5f5f5);
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 6px 12px;
  display: block;
}
.variation__input input:checked + .label {
  border: 1px solid var(--Black, #111);
  background: var(--Black-100, #f9f9f9);
}
.btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.btn-black {
  width: 100%;
}
.accesors {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.product__accessories {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.product__accessory {
  display: grid;
  grid-template-columns: 91px 1fr 0.5fr 0.3fr;
}
.product__accessory--image {
  width: 91px;
  height: 91px;
}
.product__accessory--image img {
  width: 100%;
  height: 100%;
}
.product__accessory--title {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.product__accessory--title .name {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.product__accessory--title .article {
  color: var(--Black-600, #858585);
  font-family: "Suisse Intl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.product__accessory--price {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.label-access {
  color: var(--Black-900, #757575);
  font-family: "Suisse Intl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product__accessory--price .price {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.btn-check {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 32px;
  height: 32px;
  border-radius: 1.778px;
  background: var(--Black, #111);
  cursor: pointer;
}
</style>