<script>

export default {
    name: "BreadcrumbsUi",
    data() {
        return {
            breadcrumbs: [{
                id: 1, 
                link: "#",
                name: "Главная"
            },
            {
                id: 2,
                link: "/shop",
                name: "Каталог"

            }
        ]
        }
    }

}
</script>

<template>

    <div class="breadcrumbs">
        <div class="container">
            <div class="breadcrumbs__content">
                <a
                    class="breadcrumbs__item"
                    v-for="breadcrumb in breadcrumbs"
                    :key="breadcrumb.id"
                    :href="breadcrumb.link"
                >
                    {{ breadcrumb.name }}
            </a>
            </div>
        </div>
    </div>

</template>

<style scoped>
.breadcrumbs__content{
    display: flex; 
    align-items: center;
    gap: 20px;
    padding-top: 105px;
    margin-bottom: 48px;
}
.breadcrumbs__item{
    color: var(--Black-900, #757575);
    font-family: "Suisse Intl";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    }
.breadcrumbs__item:not(:last-child):after {
    content: "/";
    position: absolute;
    top: 0;
    right: -12px;
}
.breadcrumbs__item:last-child{
    color: var(--Black-700, #A1A1A1);
}
</style>