<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import btnLink from "../ui/btnLink.vue";

export default {
  name: "HomeActionBlock",
  components: {
    Swiper,
    SwiperSlide,
    btnLink,
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper3 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper3,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },

  data() {
    return {
      news: [
        {
          id: 1,
          imgUrl: require("@/assets/image/home/news-1.png"),
          head: "Световые тренды 2024",
          date: "11.01.2024",
          excerpt: "Рассказываем про будущие направления в дизайне освещения ",
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/home/news-2.png"),
          head: "Открытие современного складского комплекса",
          date: "11.01.2024",
          excerpt:
            "Наш новый складской комплекс — это не только площадка для хранения ",
        },
        {
          id: 3,
          imgUrl: require("@/assets/image/home/news-3.png"),
          head: "Проект освещения",
          date: "11.01.2024",
          excerpt:
            "Lumatica успешно завершила работу над подсветкой элитного торгового центра, создавая уникальные световые архитектурные решения",
        },
        // {
        //   id: 4,
        //   imgUrl: require("@/assets/image/home/news-3.png"),
        //   head: "Проект освещения",
        //   date: "11.01.2024",
        //   excerpt:
        //     "Lumatica успешно завершила работу над подсветкой элитного торгового центра, создавая уникальные световые архитектурные решения",
        // },
      ],
      timeline: null,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.timeline = gsap.timeline();
    this.timeline.fromTo(
      ".news",
      { y: "50%", opacity: 0.2 },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: ".news",
          start: "top bottom",
          end: "top center",
          scrub: 2,
        },
      }
    );
  },
};
</script>
<template>
  <div class="news sector">
    <div class="container">
      <div class="news__content">
        <div class="news__head">
          <div class="head-h3">Новости</div>

          <btnLink :link="'/news'">Все новости</btnLink>
        </div>
        <div class="arrows">
          <a class="arrow-prev arrow2-prev">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M13.75 17.4805L7.26819 10.9987L13.75 4.51684"
                stroke="#111111"
                stroke-width="1.28333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <a class="arrow-next arrow2-next"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M8.25 4.51953L14.7318 11.0013L8.25 17.4832"
                stroke="#111111"
                stroke-width="1.28333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <swiper
          :slidesPerView="'auto'"
          :spaceBetween="20"
          :modules="modules"
          :navigation="{
            enabled: true,
            prevEl: '.arrow2-prev',
            nextEl: '.arrow2-next',
          }"
          :breakpoints="{
            1600: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1440: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 'auto',
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
          }"
          @swiper="onSwiper3"
          style="width: 100%"
        >
          <swiper-slide
            class="news__item"
            v-for="action in news"
            :key="action.id"
          >
            <a href="/post"><img :src="action.imgUrl" :alt="action.head" /></a>
            <div class="news__attributes">
              <div class="date">{{ action.date }}</div>
              <div class="head-news">{{ action.head }}</div>
              <div class="excerpt">{{ action.excerpt }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<style scoped>
.arrows {
  display: flex;
  align-items: center;
  gap: 18px;
}
.news__content {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.news__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.news__item {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.swiper-slide {
  width: max-content;
}
.news__item img {
  height: 396px;
  width: 622px;
}
.news__attributes {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.date {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.head-news {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.excerpt {
  overflow: hidden;
  color: var(--Black-1100, #434343);
  text-overflow: ellipsis;
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 571px;
  width: 100%;
}
.swiper {
  width: 120%;
  flex-shrink: 0;
  overflow: visible;
}
@media screen and (max-width: 1440px) {
  .news__item img {
    width: 522px;
    height: 332px;
  }
  .swiper {
    overflow: hidden;
  }
}
@media screen and (max-width: 650px) {
  .news__content {
    gap: 24px;
  }
  .news__item img {
    height: 162px;
  }
  .date {
    font-size: 10px;
  }
  .head-news {
    font-size: 12px;
  }
  .excerpt {
    font-size: 10px;
  }
}
</style>