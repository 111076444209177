<script>
import btnLink from "../ui/btnLink.vue";

export default {
  name: "HomeProducts",
  components: {
    btnLink,
  },
  data() {
    return {
      kategoryes: [
        {
          id: 1,
          imgUrl: require("@/assets/image/home/track.png"),
          head: "Трековые",
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/home/vstroy.png"),
          head: "Встраиваемые",
        },
        {
          id: 3,
          imgUrl: require("@/assets/image/home/podves.png"),
          head: "Подвесные",
        },
        {
          id: 4,
          imgUrl: require("@/assets/image/home/naklad.png"),
          head: "Накладные",
        },
        {
          id: 5,
          imgUrl: require("@/assets/image/home/lents.png"),
          head: "Светодиодные ленты",
        },
        {
          id: 6,
          imgUrl: require("@/assets/image/home/block.png"),
          head: "Блоки питания",
        },
      ],
    };
  },
};
</script>

<template>
  <div class="products-kategories sector">
    <div class="container">
      <div class="products-kategories__content">
        <div class="products-kategories__head">
          <div class="head-h3">Продукция</div>
          <btnLink :link="'/shop'" title="Сраница в разработке" class="">Каталог</btnLink>
        </div>
        <div class="products-kategories__items">
          <div
            class="products-kategories__item"
            v-for="kategory in kategoryes"
            :key="kategory.id"
          >
            <img :src="kategory.imgUrl" :alt="kategory.head" />
            <div class="head-h4">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17" fill="none">
                <path  d="M15 4.33203L19.6667 8.9987L15 13.6654" stroke="#111111" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                <path class="path-stroke" d="M19 9L3 9" stroke="#111111" stroke-width="1.6"/>
              </svg>
              {{ kategory.head }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.products-kategories__item svg {
  transition: .3s all;
  overflow: hidden;
  margin-left: -15px;
}
.products-kategories__item .path-stroke {
  transform: translate(-25px, 0);
  transition: .3s all;
}
.products-kategories__item:hover svg {
  margin-left: 0;
}
.products-kategories__item:hover .path-stroke {
  opacity: 1;
  transform: translate(0, 0);
}
.products-kategories__content {
  display: flex;
  flex-direction: column;
  gap: 44px;
}
.products-kategories__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.products-kategories__items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.products-kategories__item {
  height: 292px;
  width: 24%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 16px;
  background: var(--Black-300, #eaeaea);
}
.products-kategories__item img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s;
  height: 100%;
  transform: scale(1.1);
}
.products-kategories__item:hover img {
  transform: scale(1);
  transition: all 0.3s;
}
.products-kategories__item:nth-of-type(2),
.products-kategories__item:nth-of-type(4) {
  width: 40%;
}
.products-kategories__item:nth-of-type(3),
.products-kategories__item:nth-of-type(5) {
  width: 32.43%;
}
.head-h4 {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 6px;
}
@media (max-width: 1024px) {
  .products-kategories__items{
    gap: 12px;
  }
  .products-kategories__item{
    height: 164px;
  }
  .products-kategories__item{
    width: 48%;
  }
  .products-kategories__item:nth-of-type(2), .products-kategories__item:nth-of-type(4),.products-kategories__item:nth-of-type(3), .products-kategories__item:nth-of-type(5){
    width: 48%;
  }
  .products-kategories__content{
    gap: 24px;
  }
}
</style>