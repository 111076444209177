<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import PopupAddVue from "../persacc/PopupAdd.vue";
export default {
  name: "ProjectsBlockAcc",
  components: {
    Swiper,
    SwiperSlide,
    PopupAddVue,
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper2 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper2,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      showModal: false,
      projects: [
        {
          id: 1,
          name: "Частный дом на Копанской",
          created_at: "01.01.2022",
          updated_at: "01.01.2022",
          price: 3000,
          status: "Well Done",
          rooms: [
            {
              id: 1,
              name: "Мастер гардеробная",
            },
            {
              id: 2,
              name: "Мастер гардеробная",
            },
            {
              id: 3,
              name: "Мастер гардеробная",
            },
          ],
        },
        {
          id: 2,
          name: "Частный дом на 1-мая",
          created_at: "01.01.2022",
          updated_at: "01.01.2022",
          price: 3000,
          status: "processing",
          rooms: [
            {
              id: 1,
              name: "Мастер гардеробная",
            },
            {
              id: 2,
              name: "Мастер гардеробная",
            },
            {
              id: 3,
              name: "Мастер гардеробная",
            },
          ],
        },
        {
          id: 3,
          name: "Ресторан Чапаева, 80",
          created_at: "01.01.2022",
          updated_at: "01.01.2022",
          price: 3000,
          status: "processing",
          rooms: [
            {
              id: 1,
              name: "Мастер гардеробная",
            },
            {
              id: 2,
              name: "Мастер гардеробная",
            },
            {
              id: 3,
              name: "Мастер гардеробная",
            },
            {
              id: 4,
              name: "Мастер гардеробная",
            },
            {
              id: 5,
              name: "Мастер гардеробная",
            },
            {
              id: 6,
              name: "Мастер гардеробная",
            },
            {
              id: 7,
              name: "Мастер гардеробная",
            },
          ],
        },
      ],
      newProject: {
        name: "",
        created_at: "",
        updated_at: "",
        price: "",
        status: "",
        room: [],
      },
    };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    tooglePopup() {
      if (!this.showModal) {
        this.showModal = true;
        return;
      }
    },
    toggleSort() {
      document.querySelector(".sort__rating").classList.toggle("active");
      document.querySelector(".sort__text").classList.toggle("active");
    },
    sortItem() {
      let items = document.querySelectorAll(".sort__rating-item");

      items.forEach((item) => {
        item.addEventListener("click", () => {
          let sort = item.getAttribute("data-sort");
          console.log(sort);
          if (sort === "default") {
            document.querySelector(".sort__text span").innerHTML =
              "Сначала новые";
          }
          if (sort === "old") {
            document.querySelector(".sort__text span").innerHTML =
              "Сначала старые";
          }
          if (sort === "priceMin") {
            document.querySelector(".sort__text span").innerHTML =
              "По убыванию цены";
          }
          if (sort === "priceMax") {
            document.querySelector(".sort__text span").innerHTML =
              "По возрастанию цены";
          }
        });
      });
    },
    createProject(input) {
      this.showModal = false;
      this.newProject.name = input;
      this.$emit("createProject", this.newProject);
    },
  },
  mounted() {
    this.sortItem();
  },
  computed: {
    filteredProjects() {
      return this.projects.filter((project) => project.status === "processing");
    },
  },
};
</script>

<template>
  <div class="projects-block-acc">
    <div class="container">
      <div class="projects-block-acc__content">
        <div class="projects-block-acc__head">
          <a class="addProject" @click="tooglePopup">
            <div class="addProject__img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M4.16699 9.99984H15.8337M10.0003 4.1665V15.8332"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Создать новый проект
          </a>
        </div>
        <div class="projects-acc__wrapper">
          <div class="projects-block-acc__body">
            <div class="projects-acc__head">
              <div class="projects__title">История проектов</div>
              <div class="products__sort-rating" @click="toggleSort">
                <div class="sort__text">
                  <span>Сначала новые</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.5 9.61471L12.9575 5.15717C13.0911 5.02814 13.2701 4.95674 13.4558 4.95836C13.6415 4.95997 13.8192 5.03447 13.9505 5.1658C14.0818 5.29713 14.1563 5.47479 14.1579 5.66051C14.1595 5.84623 14.0882 6.02516 13.9591 6.15875L9.00079 11.1171C8.93506 11.1829 8.857 11.2352 8.77107 11.2708C8.68514 11.3064 8.59302 11.3248 8.5 11.3248C8.40697 11.3248 8.31485 11.3064 8.22892 11.2708C8.14299 11.2352 8.06493 11.1829 7.9992 11.1171L3.04087 6.15875C2.91184 6.02516 2.84045 5.84623 2.84206 5.66051C2.84367 5.47478 2.91817 5.29713 3.0495 5.16579C3.18083 5.03446 3.35849 4.95997 3.54421 4.95836C3.72994 4.95674 3.90886 5.02814 4.04246 5.15717L8.5 9.61471Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div class="sort__rating">
                  <div class="sort__rating-item" data-sort="default">
                    Сначала новые
                  </div>
                  <div class="sort__rating-item" data-sort="old">
                    Сначала старые
                  </div>
                  <div class="sort__rating-item" data-sort="priceMin">
                    По убыванию цены
                  </div>
                  <div class="sort__rating-item" data-sort="priceMax">
                    По возрастанию цены
                  </div>
                </div>
              </div>
            </div>

            <div class="projects-acc__body">
              <div class="projects-acc__body-head">
                <div class="projects__label">Название проекта</div>
                <div class="projects__label">Дата создания</div>
                <div class="projects__label">Дата изменения</div>
                <div class="projects__label">Стомость проекта</div>
                <div class="projects__label"></div>
              </div>

              <div class="projects-acc__body-items">
                <div
                  class="projects-acc__body-item"
                  v-for="project in projects"
                  :key="project.id"
                >
                  <img
                    class="absolute status"
                    v-if="project.status === 'Well Done'"
                    src="@/assets/icon/status.svg"
                    alt=""
                  />
                  <img
                    class="absolute status"
                    v-if="project.status === 'processing'"
                    src="@/assets/icon/status2.svg"
                    alt=""
                  />
                  <div class="projects__item">
                    <div class="projects__item-title">{{ project.name }}</div>
                    <div class="projects__item-date">
                      {{ project.created_at }}
                    </div>
                    <div class="projects__item-date">
                      {{ project.updated_at }}
                    </div>
                    <div class="projects__item-price">
                      {{ project.price }} ₽
                    </div>
                    <div class="btns">
                      <a href="" class="copied">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M3.33366 13.3332C2.41699 13.3332 1.66699 12.5832 1.66699 11.6665V3.33317C1.66699 2.4165 2.41699 1.6665 3.33366 1.6665H11.667C12.5837 1.6665 13.3337 2.4165 13.3337 3.33317M8.33366 6.6665H16.667C17.5875 6.6665 18.3337 7.4127 18.3337 8.33317V16.6665C18.3337 17.587 17.5875 18.3332 16.667 18.3332H8.33366C7.41318 18.3332 6.66699 17.587 6.66699 16.6665V8.33317C6.66699 7.4127 7.41318 6.6665 8.33366 6.6665Z"
                            stroke="#111111"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                      <a href="" class="delete">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M2.5 4.99984H17.5M15.8333 4.99984V16.6665C15.8333 17.4998 15 18.3332 14.1667 18.3332H5.83333C5 18.3332 4.16667 17.4998 4.16667 16.6665V4.99984M6.66667 4.99984V3.33317C6.66667 2.49984 7.5 1.6665 8.33333 1.6665H11.6667C12.5 1.6665 13.3333 2.49984 13.3333 3.33317V4.99984M8.33333 9.1665V14.1665M11.6667 9.1665V14.1665"
                            stroke="#111111"
                            stroke-width="1.2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="projects__active-project">
            <div class="projects-acc__head">
              <div class="projects__title">История проектов</div>
              <div class="arrows">
                <a class="projects-block-acc__prev arrow-prev">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <path
                      d="M18 23.8926L12.1074 18M12.1074 18L18 12.1075M12.1074 18L23.8926 18"
                      stroke="#111111"
                      stroke-width="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
                <a class="projects-block-acc__next arrow-next">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                  >
                    <path
                      d="M18 12.1074L23.8926 18M23.8926 18L18 23.8925M23.8926 18H12.1074"
                      stroke="#111111"
                      stroke-width="1.4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <Swiper
              class="projects-block-acc__slider"
              v-if="filteredProjects.length > 0"
              :slides-per-view="1"
              :space-between="20"
              :navigation="{
                enabled: true,
                prevEl: '.projects-block-acc__prev',
                nextEl: '.projects-block-acc__next',
              }"
              @swiper="onSwiper2"
            >
              <SwiperSlide
                class="projects__item-slide"
                v-for="project in filteredProjects"
                :key="project.id"
              >
                <div class="projects__item-active">
                  <div class="projects__item-head">
                    <div class="projects__item-title">{{ project.name }}</div>
                    <div class="projects__item-date">
                      {{ project.updated_at }}
                    </div>
                  </div>
                  <div class="projects__item-rooms">
                    <div
                      class="projects__item-room"
                      v-for="room in project.rooms"
                      :key="room"
                    >
                      {{ room.name }}
                    </div>
                  </div>

                  <div class="projects__item-price--room">
                    Промежуточный итог: <span>{{ project.price }} ₽</span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <PopupAddVue v-if="showModal" v-on:closePopup="showModal = false" @createProject="createProject" />
</template>

<style scoped>
.projects-block-acc {
  width: 100%;
}
.projects-block-acc__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.projects-block-acc__head {
  width: 100%;
  height: 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px dashed var(--Orange, #e07031);
  background: rgba(225, 106, 50, 0.1);
}
.addProject {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  color: var(--Black, #111);

  /* Title/H8 22 med */
  font-family: "Suisse Intl";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.addProject__img {
  border-radius: 100px;
  background: var(--Orange, #e07031);
  padding: 5px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projects-acc__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 19px;
}
.projects-block-acc__body {
  border-radius: 2px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(21, 30, 45, 0.05);
  padding: 28px 36px 29px 26px;
  width: 100%;
}
.projects__title {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.projects-acc__body-item {
  border-radius: 2px;
  background: var(--Black-200, #f2f2f2);
  padding: 10px 14px;
  position: relative;
}
.status {
  left: -4px;
  top: 50%;
  transform: translateY(-50%);
}
.projects-acc__body-head {
  display: grid;
  grid-template-columns: 1fr 0.3fr 0.3fr 0.35fr 0.15fr;
  align-items: center;
  padding: 0 14px 8px;
}
.projects__label {
  color: var(--Black-900, #757575);
  font-family: "Suisse Intl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.projects__item {
  display: grid;
  grid-template-columns: 1fr 0.3fr 0.3fr 0.35fr 0.15fr;
  align-items: center;
  padding: 10px 0;
}
.projects-block-acc__body {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.projects__item-title {
  overflow: hidden;
  color: var(--Black, #111);
  text-overflow: ellipsis;
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
}
.projects-acc__body-items {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.projects__item-date {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.projects__item-price {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
}
.btns {
  display: flex;
  align-items: center;
  gap: 8px;
}
.projects-acc__wrapper {
  display: flex;
  gap: 20px;
}
.projects__active-project {
  padding: 24px 26px;
  max-width: 400px;
  border-radius: 2px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(21, 30, 45, 0.05);
  border-top: 4px solid #ded967;
}
.arrows {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.projects__item-rooms {
  border-radius: 2px;
  background: var(--Black-200, #f2f2f2);
  max-height: 259px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.projects__item-rooms::-webkit-scrollbar {
  width: 3px;
}
.projects__item-rooms::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: var(--Black-700, #a1a1a1);
}
.projects__item-room {
  padding: 16px 14px 18px;
  border-bottom: 1px solid var(--Black-400, #d9d9d9);
  overflow: hidden;
  color: var(--Black, #111);
  text-overflow: ellipsis;
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
}
.projects__item-active {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.projects__item-price--room {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  color: var(--Black, #111);
  text-overflow: ellipsis;

  /* Body/Body 16 med */
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%; /* 19.52px */
  padding: 14px 6px;
}
.projects__item-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>