<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import jQuery from "jquery";
import HeaderBlock from "../block/HeaderBlock.vue";
import BreadcrumbsVue from "../ui/Breadcrumbs.vue";
import btnLink from "../ui/btnLink.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

export default {
  name: "AboutMainBlock",
  components: {
    HeaderBlock,
    BreadcrumbsVue,
    btnLink,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper3 = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper3,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  methods: {
    openMap() {
      let mobile = window.innerWidth <= 1024;
      if (mobile) {
        return;
      }
      let wrap = document.getElementById("mapWrap");
      let map = document.getElementById("mapFrame");
      let width = document.querySelector(".contacts__body").offsetWidth;
      let height = document.querySelector(".contacts__body").offsetHeight;
      wrap.classList.toggle("active");
      map.classList.toggle("active");
      if (!this.flagOpen) {
        this.standartHeaight = wrap.offsetHeight;
        this.standartWidth = wrap.offsetWidth;
        wrap.style.width = width + "px";
        wrap.style.height = height + "px";
        this.flagOpen = true;
      } else {
        wrap.style.height = this.standartHeaight + "px";
        wrap.style.width =  this.standartWidth + "px";
        this.flagOpen = false;
      }
    },
  },
  data() {
    return {
      $: jQuery,
      advantages: [
        {
          id: 1,
          head: "Индивидуальность",
          description:
            "Не бывает одинаковых проектов, поэтому мы исследуем каждый новый проект и предлагаем уникальные решения.",
        },
        {
          id: 2,
          head: "Комплексность",
          description:
            "Осуществляем работу со светом на всех этапах от проектирования до пусконаладки и постгарантийного обслуживания.",
        },
        {
          id: 3,
          head: "Профессионализм",
          description:
            "Наши менеджеры обучены не только продавать, но и работать со светом.",
        },
        {
          id: 4,
          head: "Качество",
          description:
            "Производим оборудование на заводе полного цикла с многоступенчатой проверкой качества.",
        },
        {
          id: 5,
          head: "Глобальность",
          description:
            "Все наши проектные решения и осветительное оборудование нацелены на повышение качества Вашего здоровья.",
        },
        {
          id: 6,
          head: "Заинтересованность",
          description:
            "Мы посещаем все возможные выставки и обучающие мероприятия, чтобы доводить до Вас самые последние тренды и продвигать культуру света.",
        },
      ],
      services: [
        {
          id: 1,
          head: "Консультации по световым решения Вашего проекта",
          description:
            "Дадим подробные комментарии по правильности применения световых решений в Вашем проекте.",
        },
        {
          id: 2,
          head: "Проектирование раздела света ",
          description:
            "Подготовим полный проект освещения для Вашего объекта (платная услуга).",
        },
        {
          id: 3,
          head: "Подбор и поставка оборудования",
          description: "Из наличия и под заказ.",
        },
        {
          id: 4,
          head: "Доставка оборудования на объект ",
          description: "От 500 000 руб. бесплатная",
        },
        {
          id: 5,
          head: "Монтаж и пусконаладка",
          description:
            "При необходимости осуществим монтаж нашего осветительного оборудования и настройку.",
        },
        {
          id: 6,
          head: "Гарантийное обслуживание",
          description:
            "Заменим Вам неисправное оборудования до разбирательств почему оно не работает.",
        },
      ],
      timeline: null,
      images: [
        {
          id: 1,
          imgUrl: require("@/assets/image/about/image1.png"),
        },
        {
          id: 2,
          imgUrl: require("@/assets/image/about/image2.png"),
        },
        {
          id: 3,
          imgUrl: require("@/assets/image/about/image3.png"),
        },
        {
          id: 4,
          imgUrl: require("@/assets/image/about/image4.png"),
        },
      ],
      flagOpen: false,
      standartWidth: 0,
      standartHeaight: 0, 
      flagMobile: false 
    };
  },
  
  mounted() {
    let mobile = window.innerWidth <= 1024;
    if (!mobile) {
    let symbols = document.querySelectorAll(".images-about svg");
    gsap.registerPlugin(ScrollTrigger);
    gsap.fromTo(  
      ".about-main__text",
      { opacity: 0, x: -300 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
      }
    );

    gsap.fromTo(
      ".contacts__item-phone",
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
      }
    );
    gsap.fromTo(
      ".contacts__item-email",
      { opacity: 0, x: 200 },
      {
        opacity: 1,
        x: 0,
        delay: 0.5,
        duration: 1,
      }
    );

    this.timeline = gsap.timeline();
    gsap.fromTo(
      ".about-main",
      { opacity: 1 },
      {
        opacity: 1,
        scrollTrigger: {
          trigger: ".about-main",
          start: "top top",
          end: "700",
          scrub: 2,
          pin: true,
        },
      }
    );
    this.timeline.fromTo(
      ".images-about",
      { y: "400px" },
      {
        y: 0,
        scrollTrigger: {
          start: "200",
          end: "500",
          scrub: 2,
        },
      }
    );
    symbols.forEach((item, index) => {
      gsap.fromTo(
        item,
        { opacity: 0, y: "100px" },
        { opacity: 1, y: "0px", duration: 1, delay: 0.2 * index }
      );
    });
    this.timeline.fromTo(
      ".about-image",
      {
        scale: 1,
      },
      {
        scale: 0.8,
        scrollTrigger: {
          start: "1500",
          end: "1700",
          scrub: 2,
        },
      }
    );
    this.timeline.fromTo(
      ".about-image",
      { y: "450px", scale: 1.2 },
      {
        y: 0,
        scale: 1,
        scrollTrigger: {
          start: "200",
          end: "500",
          scrub: 2,
        },
      }
    );
    console.log(document.querySelector(".about-image").offsetHeight);
    this.timeline.fromTo(
      ".advantages",
      { y: 0 },
      {
        y: 0,
        scrollTrigger: {
          trigger: ".advantages",
          start: "center 50px",
          end: "+=400",
          scrub: true,
          pin: true,
        },
      }
    );

    this.timeline.fromTo(
      ".advantages__items",
      { x: 0 },
      {
        x:
          window.innerWidth -
          document.querySelector(".advantages__items").offsetWidth -
          250 +
          "px",
        scrollTrigger: {
          trigger: ".advantages",
          start: "center 50px",
          end: "+=300",
          scrub: 2,
        },
      }
    );
    let servicesItems = document.querySelectorAll(".services__item");
    servicesItems.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, x: "100%" },
        {
          opacity: 1,
          x: 0,
          scrollTrigger: {
            trigger: item,
            start: "top bottom -=200",
            end: "top center",
            scrub: 2,
          },
        }
      );
    });
    }
    // this.timeline.fromTo(
    //   ".services__item",
    //   { opacity: 0 },
    //   {
    //     opacity: 1,
    //     scrollTrigger: {
    //       trigger: ".services__item",
    //       start: "top bottom -=300",
    //       end: "bottom center",
    //       scrub: 2,
    //     },
    //   }
    // );
  },
};
</script>

<template>
  <div class="about-main">
    <HeaderBlock class="header" :className="'black'"></HeaderBlock>
    <BreadcrumbsVue></BreadcrumbsVue>
    <div class="container">
      <div class="about-main__content">
        <div class="about-main__head">
          <div class="about-main__text">
            Уникальное пространство, в котором мы даём возможность понять как
            работает свет и предлагаем не просто купить светильники, а
            реализовать комплексные световые решения.
          </div>
          <div class="contacts">
            <div class="contacts__item contacts__item-phone">
              <div class="label">Телефон:</div>
              <a href="tel:+79284211211 " class="value">+7 (928) 422-12-21 </a>
            </div>
            <div class="contacts__item contacts__item-email">
              <div class="label">E-mail:</div>
              <a href="mailto:info@lumatica.ru" class="value"
                >info@lumatica.ru</a
              >
            </div>
          </div>
        </div>
        <div class="images-about">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="165"
            height="251"
            viewBox="0 0 165 251"
            fill="none"
          >
            <path
              d="M30.0505 223.47V0H0V250.696H164.275V223.47H30.0505Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="150"
            height="177"
            viewBox="0 0 150 177"
            fill="none"
          >
            <path
              d="M120.172 0.0791016V98.214C120.172 105.673 119.182 112.505 117.279 118.582C111.232 137.958 96.142 149.772 75.4481 149.772C53.4249 149.772 37.1617 138.923 32.0533 118.582C30.9066 114.148 30.3332 109.245 30.3332 103.925V0.0791016H0.986328V107.863C0.986328 111.566 1.19484 115.139 1.58578 118.582C4.60908 144.556 18.7091 162.968 39.5334 171.391C42.0093 172.408 44.5635 173.269 47.2219 173.999C53.6334 175.747 60.5661 176.633 67.9159 176.633C74.7704 176.633 81.208 175.747 87.1503 173.999C89.6263 173.269 92.0241 172.408 94.3176 171.391C105.003 166.749 113.787 159.03 120.172 148.703V172.695H149.519V0.0791016H120.172Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="263"
            height="178"
            viewBox="0 0 263 178"
            fill="none"
          >
            <path
              d="M197.836 0.77832C169.897 0.77832 150.219 12.5921 139.846 34.4462C130.177 13.3223 109.066 0.77832 81.1526 0.77832C58.2433 0.77832 41.4067 9.38437 30.3039 25.5011V5.08134H0.957031V177.698H30.3039V72.4171C30.3039 45.5558 47.5054 27.6396 74.3502 27.6396C101.195 27.6396 117.302 44.8256 117.302 72.4171V177.698H146.649V72.4171C146.649 45.5558 163.824 27.6396 190.669 27.6396C217.514 27.6396 233.621 44.8256 233.621 72.4171V177.698H262.968V67.3838C262.968 27.2745 236.826 0.77832 197.836 0.77832Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="149"
            height="182"
            viewBox="0 0 149 182"
            fill="none"
          >
            <path
              d="M76.9582 0.77832C41.8775 0.77832 13.5993 19.3986 3.22631 48.4245L27.2041 61.3074C33.3029 39.8184 52.6155 26.2052 76.9582 26.2052C102.734 26.2052 119.545 40.1835 119.545 63.1069V69.5484L53.3452 79.5627C21.8612 84.4916 3.61725 99.6173 0.776391 123.584C0.489699 125.774 0.359375 128.069 0.359375 130.417C0.359375 152.453 12.5568 168.466 31.7131 176.394C34.1369 177.411 36.691 178.271 39.3234 179.002C46.3343 180.984 54.049 182.001 62.2849 182.001C70.5208 182.001 78.4438 180.984 85.5329 179.002C88.2174 178.271 90.7716 177.411 93.2475 176.394C104.35 171.908 113.394 164.789 119.91 155.504V177.698H148.892V63.1069C148.892 26.5704 119.91 0.77832 76.9582 0.77832ZM68.7223 156.939C45.4742 156.939 29.7062 145.099 29.7062 128.643C29.7062 126.87 29.8365 125.175 30.1493 123.584C31.9737 113.361 39.7143 107.154 53.3452 104.99L119.545 94.9753V108.223C119.545 113.7 118.815 118.837 117.46 123.584C111.466 144.134 93.1693 156.939 68.7223 156.939Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="122"
            height="224"
            viewBox="0 0 122 224"
            fill="none"
          >
            <path
              d="M117.221 194.836C110.418 197.34 101.844 198.774 93.2429 198.774C75.7026 198.774 64.9646 188.03 64.9646 169.775V74.5084H116.383V49.0815H64.9646V0.000976562H35.6178V14.3444C35.6178 40.1103 30.2488 49.0815 14.8717 49.0815H0.537109V74.5084H35.6178V171.574C35.6178 195.723 47.737 213.3 67.9618 220.394C71.2458 221.541 74.7122 222.428 78.4131 223.002C81.9837 223.575 85.7368 223.862 89.6723 223.862C94.0769 223.862 98.4815 223.575 102.756 223.002C107.134 222.428 111.357 221.567 115.37 220.394C117.377 219.846 119.306 219.194 121.156 218.49L117.221 194.836Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="44"
            height="245"
            viewBox="0 0 44 245"
            fill="none"
          >
            <path
              d="M7.2005 72.0772V244.693H36.5474V72.0772H7.2005ZM21.8739 0.438477C9.33767 0.438477 0.0332031 10.1138 0.0332031 22.2926C0.0332031 34.4715 9.33767 43.7816 21.8739 43.7816C34.4102 43.7816 43.6886 34.1063 43.6886 22.2926C43.6886 10.4789 34.0453 0.438477 21.8739 0.438477Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="169"
            height="182"
            viewBox="0 0 169 182"
            fill="none"
          >
            <path
              d="M144.969 120.037C144.578 121.237 144.161 122.436 143.692 123.584C135.821 143.013 115.987 155.139 92.3481 155.139C68.7091 155.139 48.1715 142.856 37.8245 123.584C32.8204 114.378 30.0577 103.555 30.0577 91.7415C30.0577 54.8659 56.5637 27.6396 91.9832 27.6396C117.056 27.6396 138.167 41.9569 144.969 62.7418L168.947 49.1286C158.913 19.7638 128.497 0.77832 91.9832 0.77832C40.4568 0.77832 0.710938 38.7492 0.710938 91.7415C0.710938 103.112 2.58749 113.804 6.02779 123.584C14.8371 148.541 33.9933 167.501 58.8834 176.394C61.646 177.385 64.4869 178.271 67.4059 179.002C75.1727 180.957 83.4085 182.001 91.9832 182.001C100.558 182.001 108.69 180.957 116.352 179.002C119.245 178.271 122.086 177.385 124.822 176.394C145.96 168.726 162.145 153.575 168.947 133.65L151.224 123.584L144.969 120.037Z"
              fill="white"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="149"
            height="182"
            viewBox="0 0 149 182"
            fill="none"
          >
            <path
              d="M77.0656 0.77832C41.9849 0.77832 13.7067 19.3986 3.33365 48.4245L27.3116 61.3074C33.3842 39.8184 52.7228 26.2052 77.0656 26.2052C102.816 26.2052 119.652 40.1835 119.652 63.1069V69.5484L53.4266 79.5627C21.9687 84.4916 3.72459 99.6173 0.883733 123.584C0.597041 125.774 0.466797 128.069 0.466797 130.417C0.466797 152.453 12.6642 168.466 31.8204 176.394C34.2442 177.411 36.7985 178.271 39.4309 179.002C46.4418 180.984 54.1564 182.001 62.3923 182.001C70.6282 182.001 78.5512 180.984 85.6143 179.002C88.2988 178.271 90.879 177.411 93.3289 176.394C104.458 171.908 113.476 164.789 120.017 155.504V177.698H148.999V63.1069C148.999 26.5704 120.017 0.77832 77.0656 0.77832ZM68.8298 156.939C45.5556 156.939 29.8137 145.099 29.8137 128.643C29.8137 126.87 29.97 125.175 30.2567 123.584C32.0811 113.361 39.7957 107.154 53.4266 104.99L119.652 94.9753V108.223C119.652 113.7 118.923 118.837 117.567 123.584C111.573 144.134 93.2768 156.939 68.8298 156.939Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="images-about-mob">
          <svg width="720" height="120" viewBox="0 0 720 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2438 105.162V0H0V117.975H77.8655V105.162H14.2438Z" fill="white"/>
            <path d="M153.183 36.7427V82.9237C153.183 86.4336 152.714 89.649 151.812 92.5085C148.946 101.627 141.793 107.186 131.984 107.186C121.545 107.186 113.836 102.081 111.415 92.5085C110.872 90.4221 110.6 88.1149 110.6 85.6114V36.7427H96.6895V87.4645C96.6895 89.2072 96.7883 90.8885 96.9736 92.5085C98.4066 104.732 105.09 113.396 114.961 117.36C116.134 117.839 117.345 118.244 118.605 118.587C121.644 119.41 124.93 119.827 128.414 119.827C131.663 119.827 134.714 119.41 137.531 118.587C138.704 118.244 139.841 117.839 140.928 117.36C145.993 115.176 150.156 111.543 153.183 106.683V117.974H167.093V36.7427H153.183Z" fill="white"/>
            <path d="M290.957 34.719C277.714 34.719 268.386 40.2784 263.47 50.5627C258.886 40.622 248.88 34.719 235.649 34.719C224.79 34.719 216.81 38.7689 211.547 46.3532V36.7439H197.637V117.975H211.547V68.4313C211.547 55.7907 219.7 47.3596 232.425 47.3596C245.149 47.3596 252.784 55.4471 252.784 68.4313V117.975H266.694V68.4313C266.694 55.7907 274.835 47.3596 287.559 47.3596C300.284 47.3596 307.918 55.4471 307.918 68.4313V117.975H321.829V66.0627C321.829 47.1877 309.438 34.719 290.957 34.719Z" fill="white"/>
            <path d="M383.917 34.719C367.289 34.719 353.885 43.4815 348.968 57.1407L360.334 63.2032C363.224 53.0908 372.379 46.6846 383.917 46.6846C396.135 46.6846 404.103 53.2626 404.103 64.05V67.0813L372.724 71.7939C357.801 74.1134 349.154 81.2314 347.807 92.5097C347.671 93.5406 347.609 94.6206 347.609 95.7251C347.609 106.095 353.391 113.631 362.471 117.361C363.62 117.84 364.83 118.245 366.078 118.589C369.401 119.521 373.058 120 376.962 120C380.866 120 384.621 119.521 387.981 118.589C389.254 118.245 390.464 117.84 391.638 117.361C396.901 115.25 401.187 111.9 404.276 107.531V117.975H418.013V64.05C418.013 46.8564 404.276 34.719 383.917 34.719ZM380.013 108.206C368.994 108.206 361.52 102.634 361.52 94.8906C361.52 94.056 361.581 93.2583 361.73 92.5097C362.594 87.6989 366.263 84.7781 372.724 83.7595L404.103 79.0469V85.2813C404.103 87.8585 403.757 90.2761 403.115 92.5097C400.273 102.18 391.601 108.206 380.013 108.206Z" fill="white"/>
            <path d="M489.27 105.334C486.046 106.512 481.982 107.187 477.905 107.187C469.591 107.187 464.501 102.131 464.501 93.5405V48.7095H488.873V36.7439H464.501V13.6472H450.591V20.397C450.591 32.5222 448.046 36.7439 440.757 36.7439H433.963V48.7095H450.591V94.3873C450.591 105.752 456.335 114.023 465.922 117.361C467.479 117.901 469.122 118.318 470.876 118.588C472.568 118.858 474.347 118.993 476.213 118.993C478.3 118.993 480.388 118.858 482.414 118.588C484.49 118.318 486.491 117.913 488.393 117.361C489.345 117.104 490.259 116.797 491.136 116.465L489.27 105.334Z" fill="white"/>
            <path d="M512.011 36.7418V117.973H525.921V36.7418H512.011ZM518.966 3.02954C513.024 3.02954 508.613 7.58261 508.613 13.3138C508.613 19.045 513.024 23.4263 518.966 23.4263C524.908 23.4263 529.306 18.8732 529.306 13.3138C529.306 7.75442 524.735 3.02954 518.966 3.02954Z" fill="white"/>
            <path d="M618.55 90.8407C618.364 91.4052 618.167 91.9697 617.944 92.5097C614.214 101.653 604.812 107.359 593.608 107.359C582.403 107.359 572.668 101.579 567.764 92.5097C565.392 88.1776 564.082 83.0845 564.082 77.5251C564.082 60.1719 576.646 47.3596 593.435 47.3596C605.319 47.3596 615.325 54.0971 618.55 63.8782L629.915 57.472C625.159 43.6533 610.742 34.719 593.435 34.719C569.011 34.719 550.172 52.5876 550.172 77.5251C550.172 82.8759 551.061 87.9076 552.692 92.5097C556.868 104.254 565.948 113.176 577.745 117.361C579.055 117.828 580.401 118.245 581.785 118.589C585.466 119.509 589.37 120 593.435 120C597.499 120 601.353 119.509 604.985 118.589C606.357 118.245 607.703 117.828 609 117.361C619.019 113.753 626.691 106.623 629.915 97.2469L621.515 92.5097L618.55 90.8407Z" fill="white"/>
            <path d="M685.903 34.719C669.275 34.719 655.871 43.4815 650.955 57.1407L662.32 63.2032C665.198 53.0908 674.365 46.6846 685.903 46.6846C698.109 46.6846 706.089 53.2626 706.089 64.05V67.0813L674.698 71.7939C659.788 74.1134 651.14 81.2314 649.793 92.5097C649.657 93.5406 649.596 94.6206 649.596 95.7251C649.596 106.095 655.377 113.631 664.457 117.361C665.606 117.84 666.817 118.245 668.065 118.589C671.388 119.521 675.044 120 678.948 120C682.852 120 686.607 119.521 689.955 118.589C691.228 118.245 692.451 117.84 693.612 117.361C698.887 115.25 703.161 111.9 706.262 107.531V117.975H720V64.05C720 46.8564 706.262 34.719 685.903 34.719ZM681.999 108.206C670.968 108.206 663.506 102.634 663.506 94.8906C663.506 94.056 663.58 93.2583 663.716 92.5097C664.581 87.6989 668.237 84.7781 674.698 83.7595L706.089 79.0469V85.2813C706.089 87.8585 705.743 90.2761 705.101 92.5097C702.26 102.18 693.587 108.206 681.999 108.206Z" fill="white"/>
            </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="advantages">
    <img src="@/assets/image/about/about1.png" alt="" class="about-image" />
    <div class="container">
      <div class="advantages__head">
        <div class="advantages__title">
          Особенность нашего подхода в качестве каждого этапа работы.
        </div>
        <div class="sub-head-h2">
          Наш шоурум подтверждает нашу уникальность - он не имеет аналогов и
          даёт возможность увидеть как работает свет, какие эффекты можно
          достичь, изучить историю света, посмотреть и пощупать осветительное
          оборудование и протестировать работу света с Вашими поверхностями
          (работа с образцами отделок в нашей лаборатории).
        </div>
      </div>
      <div class="advantages__items">
        <div class="advantages__item" v-for="item in advantages" :key="item.id">
          <div class="advantages__item-title">{{ item.head }}</div>
          <div class="advantages__item-text">{{ item.description }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="services">
    <div class="container">
      <div class="services__content">
        <div class="services__head">
          <div class="title-about">Наши услуги</div>
          <btnLink :className="'about'" class="services__btn">Перейти к каталогу товаров</btnLink>
        </div>
        <div class="services__items">
          <div class="services__item" v-for="item in services" :key="item.id">
            <div class="services__item-title">{{ item.head }}</div>
            <div class="services__item-text">
              {{ item.description }}
            </div>
          </div>
        </div>
        <btnLink :className="'about'" class="services__btn-mob">Перейти к каталогу товаров</btnLink>
      </div>
    </div>
  </div>
  <div class="contacts">
    <div class="container">
      <div class="contacts__content">
        <div class="contacts__head">
          <div class="title-about">Наши магазины</div>
          <div class="hidden_address">
            <div class="contacts__item">
              <div class="label">Адрес:</div>
              <div class="value">ул. Красных Партизан, 98</div>
            </div>
            <div class="contacts__item">
              <div class="label">Телефон:</div>
              <a href="tel:+7 (928) 422-12-21 " class="value">+7 (928) 422-12-21 </a>
            </div>
            <div class="contacts__item">
              <div class="label">E-mail:</div>
              <a href="mailto:info@lumatica.ru" class="value"
                >info@lumatica.ru</a
              >
            </div>
            <div class="contacts__item">
              <div class="label">Режим работы:</div>
              <div class="value">10:00 - 20:00</div>
            </div>
          </div>
        </div>
        <div class="contacts__body">
          <div class="contacts__address">
            <div class="contacts__list">
              <div class="city">Краснодар</div>
              <div class="contacts__item">
                <div class="label">Адрес:</div>
                <div class="value">ул. Красных Партизан, 98</div>
              </div>
              <div class="contacts__item">
                <div class="label">Телефон:</div>
                <a href="tel:+7 (928) 422-12-21 " class="value">+7 (928) 422-12-21 </a>
              </div>
              <div class="contacts__item">
                <div class="label">E-mail:</div>
                <a href="mailto:info@lumatica.ru" class="value"
                  >info@lumatica.ru</a
                >
              </div>
              <div class="contacts__item">
                <div class="label">Режим работы:</div>
                <div class="value">10:00 - 20:00</div>
              </div>
            </div>
           <div class="mapHead">
              <div id="mapWrap" class="map" @click="openMap">
                <iframe
                  id="mapFrame"
                  src="https://yandex.ru/map-widget/v1/?um=constructor%3A89964e442adb6afae78bbefa2d395095c802f482b53598073760885e76c30bc7&amp;source=constructor"
                  width="341"
                  height="240"
                  frameborder="0"
                ></iframe>
              </div>
           </div>
          </div>
          <swiper
            :slidesPerView="'auto'"
            :spaceBetween="20"
            :modules="modules"
            @swiper="onSwiper3"
          >
            <swiper-slide
              class="action__item"
              v-for="action in images"
              :key="action.id"
            >
              <img :src="action.imgUrl" />
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper {
  flex-shrink: 0;
  width: calc(50% + 200px)
}
.swiper-slide img {
  height: 408px;
  object-fit: cover;
  object-position: center;
}
.swiper-slide {
  width: max-content;
  height: 100%;
}
.container {
  width: 100%;
}
.map iframe {
  filter: grayscale(1);
  height: 164px;
}
.contacts__content {
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding-top: 214px;
  width: 100%;
}
.contacts__body {
  display: flex;
  gap: 180px;
}
.contacts__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.contacts__address {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.city {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.services__content {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 253px;
}
.title-about {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 64px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.services__head {
  display: flex;
  flex-direction: column;
  gap: 28.5px;
  align-items: flex-start;
}
.services__items {
  display: flex;
  flex-direction: column;
  max-width: 798px;
}
.services__item {
  padding: 31px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid var(--Black-1100, #434343);
}
.services__btn-mob {
  display: none;
}
.services__item-title {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 24px;
  font-style: normal;
  font-weight: 450;
  line-height: 100%;
}
.services__item-text {
  color: var(--Black-800, #858585);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}
.about-main__content {
  display: flex;
  flex-direction: column;
  gap: 98px;
}
.about-main__text {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 695px;
}
.about-main__head {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.about-image {
  margin-top: 36px;
}
.contacts {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
}
.contacts__item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  
}
.contacts__content .contacts__item{
  transform: translateY(0px);
  opacity: 1;
  transition: all 0.5s;
}
.label {
  color: var(--Black-900, #757575);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.value {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.images-about {
  display: flex;
  align-items: end;
  width: 100%;
  gap: 36px;
  justify-content: space-between;
}
.images-about-mob {
  display: none;
}
.advantages {
  padding-top: 100px;
}
.advantages__head {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 100px;
}
.advantages__title {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 770px;
}
.sub-head-h2 {
  line-height: 120%;
  max-width: 637px;
}
.advantages__items {
  display: flex;
  gap: 24px;
  margin-top: 168px;
  width: max-content;
}
.advantages__item {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 24px;
  width: 393px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--White, #fff);
  background: url(@/assets/image/about/bg-items.svg) right center no-repeat;
  
}
.advantages__item-title {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.advantages__item-text {
  color: var(--Black-600, #b0b0b0);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}
.map iframe {
  pointer-events: none;
}
.map.active {
  flex-shrink: 0;
  z-index: 3;
  transition: all 0.5s;
}
.map {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  flex-grow: 0;
}
.contacts__address {
  position: relative;
}
.map.active {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.map.active iframe {
  width: 100%;
  height: 100%;
}
.mapHead{
  position: relative;
  height: 164px;
}
.contacts__body:has(.map.active) .contacts__content.contacts__item{
  transform: translateY(50px);
  opacity: 0;
  transition: all 0.5s;
}
.hidden_address{
  opacity: 0;
  transition: all 0.5s;
  transform: translateY(-100%);
  display: flex;
  gap: 24px;
}
.contacts__head{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contacts__content:has(.map.active) .hidden_address{
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s;
}
@media screen and (max-width: 1440px) {
  .advantages {
    padding-top: 0;
  }
  .about-image {
    height: 597px;
  }
}
@media screen and (max-width: 1024px) {
  .about-main__text {
    font-size: 19px;
    max-width: 496px;
  }
  .label {
    font-size: 14px;
  }
  .value {
    font-size: 17px;
  }
  .contacts {
    gap: 20px;
  }
  .contacts__item {
    gap: 4px;
  }
  .about-image {
    height: 418px;
  }
  .advantages__head {
    gap: 26px;
    flex-direction: column;
  }
  .advantages__item {
    gap: 18px;
    width: 361px;
  }
  .advantages__items {
    margin-top: 84px;
    overflow-x: scroll;
    width: 100%;
  }
  .advantages__item-title {
    font-size: 20px;
  }
  .advantages__item-text {
    font-size: 15px;
  }
  .services__content {
    padding-top: 133px;
    flex-direction: column;
  }
  .services__head {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
  .title-about {
    font-size: 58px;
  }
  .services__content {
    gap: 36px;
  }
  .services__items {
    max-width: 100%;
  }
  .services__item {
    padding: 24px 0;
    gap: 12px;
  }
  .services__item-title {
    font-size: 18px;
  }
  .services__item-text {
    font-size: 15px;
  }
  .contacts__content {
    padding-top: 132px;
  }
  .hidden_address {
    display: none;
  }
  .contacts__body {
    gap: 66px;
    flex-direction: column;
  }
  .contacts__address {
    flex-direction: row;
    justify-content: space-between;
  }
  .mapHead {
    width: 396px;
    height: 190px;
  }
  .map {
    width: 100%;
    height: 100%;
  }
  .map iframe {
    width: 100%;
    height: 100%;
  }
  .swiper {
    width: 100%;
  }
  .swiper-slide img {
    height: 328px;
  }
  .images-about {
    display: none;
  }
  .images-about-mob {
    width: 100%;
    display: block;
  }
  .images-about-mob svg {
    width: 100%;
    height: auto;
  }
  .advantages__items::-webkit-scrollbar{
    display: none;
  }
  
}
@media screen and (max-width: 650px) {
  .about-main__head {
    gap: 32px;
    flex-direction: column;
  }
  .about-main__text {
    font-size: 16px;
  }
  .contacts {
    flex-direction: row;
    gap: 38px;
    ailign-items: flex-start;
  }
  .about-main__content {
    gap: 92px;
  }
  .about-image {
    margin-top: 32px;
    height: 244px;
  }
  .advantages__title {
    font-size: 21px;
  }
  .advantages__head {
    margin-top: 92px;
  }
  .advantages__items {
    margin-top: 56px;
  }
  .advantages__item {
    gap: 12px;
    width: 289px;
    padding: 26px 23px;
  }
  .advantages__item-title {
    font-size: 17px;
  }
  .advantages__item-text {
    font-size: 14px;
  }
  .title-about{
    font-size: 32px;
  }
  .services__btn {
    display: none;
  }
  .services__btn-mob {
    display: block;
    max-width: 219px;
  }
  .services__item-title {
    font-size: 16px;
  }
  .services__item-text {
    font-size: 14px;
  }
  .contacts__content {
    padding-top: 92px;
    gap: 32px;
  }
  .city {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .contacts__list {
    gap: 12px;
  }
  .contacts__address {
    flex-direction: column;
  }
  .contacts__body {
    gap: 60px;
  }
  .mapHead {
    height: 164px;
    width: 100%;
  }
  .swiper-slide img {
    height: 222px;
  }
}
</style>