<script>
import ProductMiniVue from "../product/ProductMini.vue";

export default {
  name: "ShopItemsBlock",
  components: {
    ProductMiniVue,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      
    };
  },
};
</script>

<template>
  <div class="shopItems">
    <div class="shopItems__content">
      <div class="products">
        <ProductMiniVue
          v-for="product in products"
          :key="product.id"
          :product="product"
        />
      </div>
    </div>
  </div>
</template>
<style scoped>
.shopItems{
  width: 100%;
}
.shopItems__content {
  display: flex;
  justify-content: space-between;
}
.products {
  display: flex;
  gap: 28px 20px;
  flex-wrap: wrap;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .product-mini{
  max-width: calc(33% - 12px);
}
}
@media screen and (max-width: 650px) {
  .product-mini{
    max-width: calc(50% - 10px);
  }
}

</style>