<script>
import btnLink from "../ui/btnLink.vue";
export default {
  name: "ShopMainBlock",
  components: {
    btnLink,
  },
};
</script>

<template>
  <section class="shop-main-block sector">
    <div class="container">
      <div class="shop-main-block__content">
        <div class="shop-main-block__head">
          <div class="shop__title">
            Как выбрать накладной светильник, который сделает ваш дом ярче
          </div>
          <p class="description">
            Подробное руководство для выбора идеального освещения
          </p>
          <btnLink class="shop__btn">Подробнее</btnLink>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.shop-main-block {
  background-color: var(--Black-300, #eaeaea);
  background-image: url(@/assets/image/shop/bg.png);
  background-position: top right;
  background-repeat: no-repeat;
  padding-bottom: 79px;
}
.shop__btn {
  width: max-content;
  margin-top: 12px;
}
@media screen and (max-width: 1440px) {
  .shop-main-block {
    background-position: top left 200px;
  }
}
.shop__title {
  font-family: "Suisse Intl";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 672px;
  background: linear-gradient(98.65deg, #2d2d2d -17.04%, #616161 137.01%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.shop-main-block__head {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
@media screen and (max-width: 1024px) {
  .shop__title {
    font-size: 22px;
  }
  .description {
    font-size: 14px;
  }
  .shop-main-block__head {
    gap: 14px;
  }
}
@media screen and (max-width: 650px) {
  .shop__title {
    font-family: "Suisse Intl";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 19.5px */
    max-width: 276px;
  }
  .description {
    font-family: "Suisse Intl";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 225px;
  }
  .shop-main-block__head{
    gap: 10px;
  }
  .shop-main-block{
    padding-bottom: 30px;
    padding-top: 96px;
    background-position: top 69% left 141px;
    background-size: 130%;
  }

}
</style>