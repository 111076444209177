<script>
import HeaderBlock from "../block/HeaderBlock.vue";
import FooterBlock from "../block/FooterBlock.vue";
import BreadcrumbsUi from "../ui/Breadcrumbs.vue";
// import btnLink from "../ui/btnLink.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Modal } from "vue3-modal";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import Cookies from "vue3-cookies";
import axios from "axios";
export default {
  name: "ProductPage",
  components: {
    HeaderBlock,
    FooterBlock,
    BreadcrumbsUi,
    // btnLink,
    Swiper,
    SwiperSlide,
    Modal,
  },
  setup() {
    const swiperActiveIndex = 0; // начальный активный индекс
    const onSwiper = (swiper) => {
      console.log(swiper.activeIndex);
    };
    return {
      onSwiper,
      swiperActiveIndex,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      product: {},
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      gallery: [
        {
          id: 1,
          src: require("@/assets/image/product/1.png"),
        },
        {
          id: 2,
          src: require("@/assets/image/product/2.png"),
        },
        {
          id: 3,
          src: require("@/assets/image/product/3.png"),
        },
        {
          id: 4,
          src: require("@/assets/image/product/4.png"),
        },
      ],
      quantity: 1,
      activeIndex: null,
      attributes: [
        {
          title: "Основные характеристики",
          description:
            "Декоративные подвесные светильники с направленным светом в восьмигранном корпусе. Представлены с декоративным рассеивателем.",
        },
        {
          title: "Документы",
          description:
            "Декоративные подвесные светильники с направленным светом в восьмигранном корпусе. Представлены с декоративным рассеивателем.",
        },
        {
          title: "Наличие в магазинах (1)",
          description:
            "Декоративные подвесные светильники с направленным светом в восьмигранном корпусе. Представлены с декоративным рассеивателем.",
        },
      ],
      variations: [],
      show: false,
      categoryName: "",
      isModalOpen: false,
      activeImageIndex: 0,
      selectedAttr: [],
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 1024;
    },
  },
  methods: {
    openModal(index) {
      this.activeImageIndex = index;
      this.isModalOpen = true;
      this.$nextTick(() => {
        const images = this.$refs.modalImages;
        if (images && images[index]) {
          images[index].scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
    },
    closeModal() {
      this.isModalOpen = false;
    },
    onSlideChange(swiper) {
      this.activeImageIndex = swiper.activeIndex;
    },
    toggle(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    beforeEnter: function (el) {
      el.style.height = "0";
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function (el) {
      el.style.height = "0";
    },
    increment() {
      this.quantity++;
    },
    decrement() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    priceRU() {
      if (this.product.price) {
        this.product.price = Number(this.product.price).toLocaleString("ru-RU");
      }
      if (this.product.productPrice) {
        this.product.price = Number(this.product.productPrice).toLocaleString(
          "ru-RU"
        );
      }
      console.log("Price", Number(this.product.price).toLocaleString("ru"));
    },
    sortAttributes(groups) {
      console.log("groupedAttributes", groups);
      const groupedAttributes = {};
      groups.forEach((item) => {
        if (item.attrGroup === "Производитель") {
          this.categoryName = item.attr;
          return;
        }
        if (!groupedAttributes[item.attrGroup]) {
          groupedAttributes[item.attrGroup] = [];
        }
        groupedAttributes[item.attrGroup].push({
          attr: item.attr,
          id: item.attrId,
        });
      });

      let idCounter = 1;

      const result = Object.keys(groupedAttributes).map((group, index) => {
        return {
          id: index + 1,
          title: group,
          inputs: groupedAttributes[group].map((attr) => ({
            id: idCounter++,
            title: attr,
          })),
        };
      });

      this.variations = result;
      console.log("Variations", this.variations);
    },
    fetchProduct() {
      this.productId = this.$route.params.id;
      
      let params = `&auth=${this.user.username}:${this.user.auth_key}&id=${this.productId}`;
      
      axios
        .get(this.apiUrl + "api/product/get-group-product-by-id" + params)
        .then((response) => {
          console.log("fetchProduct", response.data.group);
          if (response.data.group === undefined || !response.data.group) {
            this.product = response.data;
            this.sortAttributes(this.product.attribute);
            this.priceRU();
          } else {
            this.product = response.data.group[0];
            this.selectedAttr = response.data.group[0].selectedAttr;
            console.log("fetchProduct", response.data);
            this.sortAttributes(response.data.allAttrGroup);
            this.priceRU();
          }
          
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
          // this.fetchStandartProduct();
        });
    },
    // fetchStandartProduct() {
    //   this.productId = this.$route.params.id;
    //   console.log("fetchProduct1", this.productId);
    //   let params = `&auth=${this.user.username}:${this.user.auth_key}&id=${this.productId}`;
    //   axios
    //     .get(this.apiUrl + "api/product/get-product-by-id" + params)
    //     .then((response) => {
    //       this.product = response.data;
    //       // this.selectedAttr = response.data.group[0].selectedAttr;
    //       console.log("fetchProduct", response.data);
    //       this.sortAttributes(this.product.attribute);
    //       this.priceRU();
    //     })
    //     .catch((error) => {
    //       console.error("Error fetchingStandart product:", error);
    //     });
    // },
    saveProductIdInCookies(productId) {
      let viewedProducts = Cookies.get("viewedProducts");
      viewedProducts = viewedProducts ? JSON.parse(viewedProducts) : [];

      if (!viewedProducts.includes(productId)) {
        viewedProducts.push(productId);

        Cookies.set("viewedProducts", JSON.stringify(viewedProducts), {
          expires: 7,
        });
      }
    },
    isSelected(id) {
      return this.selectedAttr.includes(id);
    },
    scrollToAnchor(anchorId) {
      const element = document.getElementById(anchorId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  created() {
    this.fetchProduct();
  },
};
</script>

<template>
  <HeaderBlock :className="'white'"></HeaderBlock>
  <BreadcrumbsUi></BreadcrumbsUi>
  <div class="product">
    <div class="container">
      <div class="product__head">
        <div class="product__gallery" v-if="isDesktop">
          <div
            class="image"
            v-for="item in product.productPhoto"
            :key="item.id"
          >
            <img :src="apiDomain + '/web/uploads/products/' + item" alt="" />
          </div>
        </div>
        <!-- Слайдер для планшета -->
        <div class="swiper-container" v-else>
          <swiper
            :slides-per-view="1"
            :space-between="10"
            :pagination="true"
            :modules="modules"
            @slideChange="onSlideChange"
          >
            <swiper-slide
              v-for="(item, index) in product.productPhoto"
              :key="item.id"
              @click="openModal(index)"
            >
              <img :src="apiDomain + '/web/uploads/products/' + item" alt="" />
            </swiper-slide>
          </swiper>
        </div>

        <!-- Модальное окно с изображениями -->
        <Modal v-if="isModalOpen" @close="closeModal">
          <div class="modal-overlay" @click="closeModal"></div>
          <div class="modal-content">
            <div class="modal-close" @click="closeModal">
              <div class="close">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M16.5 5.5L5.5 16.5M5.5 5.5L16.5 16.5"
                    stroke="white"
                    stroke-width="1.16667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div class="gallery_modal">
              <div
                class="image"
                v-for="(item, index) in product.productPhoto"
                :key="index"
              >
                <img
                  :src="apiDomain + '/web/uploads/products/' + item"
                  alt=""
                  ref="modalImages"
                />
              </div>
            </div>
          </div>
        </Modal>
        <div class="product__info">
          <div class="name">
            <div class="articul__name">
              Наименование: {{ product?.groupName }}{{ product?.name }}
            </div>
            <div class="product__name">{{ product?.groupCategoryName }} {{ product?.name }}</div>
            <div class="description">
              {{ product?.groupDescription }} {{ product?.description }}
            </div>
          </div>
          <div class="allAtribetes">
            <div
              class="attribute accordion"
              v-for="(attribute, index) in attributes"
              :key="index"
              @click="toggle(index)"
            >
              <div class="attribute__title">
                {{ attribute.title }}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4819 8.25L11.0001 14.7318L4.51831 8.25"
                    stroke="#111111"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <transition
                v-if="attribute.title === 'Основные характеристики'"
                name="accordion"
                @before-enter="beforeEnter"
                @enter="enter"
                @before-leave="beforeLeave"
                @leave="leave"
              >
                <div
                  class="attributes attribute__description body"
                  v-show="activeIndex === index"
                >
                  <div class="attributes__item">
                    <p>Артикул</p>
                    <span class="line-item"></span>
                    <p class="naming">{{ product.id }}</p>
                  </div>
                  <div class="attributes__item">
                    <p>Бренд</p>
                    <span class="line-item"></span>
                    <p class="naming">{{ categoryName }}</p>
                  </div>
                  <div class="attributes__item">
                    <p>Наименование</p>
                    <span class="line-item"></span>
                    <p class="naming">{{ product.name }}</p>
                  </div>
                  <div class="attributes__item">
                    <p>Рабочая температура</p>
                    <span class="line-item"></span>
                    <p class="naming">{{ style }}</p>
                  </div>
                </div>
              </transition>
              <transition
                v-else-if="attribute.title !== 'Основные характеристики'"
                name="accordion"
                @before-enter="beforeEnter"
                @enter="enter"
                @before-leave="beforeLeave"
                @leave="leave"
              >
                <div
                  class="attribute__description body"
                  v-show="activeIndex === index"
                >
                  {{ attribute.description }}
                </div>
              </transition>
            </div>
          </div>
          <div class="price__wrapper">
            <div class="product__price">
              <div class="price">
                <div class="designer_cashback">
                  + 1 846
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                      fill="white"
                    />
                    <path
                      d="M9.01908 11.8731V12.9959H12.1955V14.2097H9.01908V16H7.20191V14.2097H6V12.9959H7.20191V11.8731H6V10.28H7.20191V5H10.5215C13.1542 5 15 5.69793 15 8.42897C15 11.0386 13.1542 11.8731 10.5215 11.8731H9.01908ZM9.01908 10.28H10.593C11.9952 10.28 13.1256 9.97655 13.1256 8.42897C13.1256 6.89655 11.9952 6.60828 10.593 6.60828H9.01908V10.28Z"
                      fill="#E07031"
                    />
                  </svg>
                </div>
                <div>
                  <span class="price__value">{{ product.price }}</span> ₽
                </div>
              </div>
              <div class="ps">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                >
                  <path
                    d="M6.99984 9.83268V7.49935M6.99984 5.16602H7.00567M12.8332 7.49935C12.8332 10.721 10.2215 13.3327 6.99984 13.3327C3.77818 13.3327 1.1665 10.721 1.1665 7.49935C1.1665 4.27769 3.77818 1.66602 6.99984 1.66602C10.2215 1.66602 12.8332 4.27769 12.8332 7.49935Z"
                    stroke="#B0B0B0"
                    stroke-width="0.816667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Базовая цена
              </div>
            </div>
            <div class="product__add">
              <a @click="scrollToAnchor('variations')" class="modificationBtn"
                >Перейти к модификациям</a
              >
              <a href="" class="addToWishlist"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    d="M13.725 10.3333C14.7308 9.36 15.75 8.19333 15.75 6.66667C15.75 5.69421 15.3589 4.76158 14.6626 4.07394C13.9664 3.38631 13.0221 3 12.0375 3C10.8495 3 10.0125 3.33333 9 4.33333C7.9875 3.33333 7.1505 3 5.9625 3C4.97788 3 4.03359 3.38631 3.33737 4.07394C2.64114 4.76158 2.25 5.69421 2.25 6.66667C2.25 8.2 3.2625 9.36667 4.275 10.3333L9 15L13.725 10.3333Z"
                    stroke="#111111"
                    stroke-width="1.05"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="product__variations" id="variations">
        <div class="title">Модификации</div>
        <div class="product__variation">
          <div class="product__variations-inputs">
            <div
              class="variation"
              v-for="variation in variations"
              :key="variation.id"
            >
              <div class="variation__title">{{ variation.title }}</div>
              <div class="variation__inputs">
                <div
                  class="variation__input"
                  v-for="input in variation.inputs"
                  :key="input.id"
                >
                  <input
                    :id="input.title.id"
                    :name="'variation' + variation.title + variation.id"
                    :value="input.title.id"
                    :checked="isSelected(input.title.id)"
                    class="radio"
                    type="radio"
                  />
                  <label class="label" :for="input.title.id">
                    {{ input.title.attr }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modif__image">
            <img src="@/assets/image/product/modif.png" alt="" />
            <div class="attribute accordion" @click="toggle(45)">
              <div class="attribute__title">
                Основные характеристики
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M17.4819 8.25L11.0001 14.7318L4.51831 8.25"
                    stroke="#111111"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <transition
                name="accordion"
                @before-enter="beforeEnter"
                @enter="enter"
                @before-leave="beforeLeave"
                @leave="leave"
              >
                <div
                  class="attribute__description body"
                  v-show="activeIndex === 45"
                ></div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="product__calculation">
        <div class="product__calculation-info">
          <div class="image--calc">
            <img :src="gallery[0].src" alt="" />
          </div>
          <div class="col first">
            <div class="articul">Артикул: 1728507</div>
            <div class="product__name--calc">CM-PUNTO 50-6-940-24-</div>
            <div class="atribute-calc">Световой поток прибора: 250 лм</div>
          </div>
          <div class="col cashback">
            <div class="articul planshet">Кешбэк</div>
            <div class="designer_cashback">
              + 1 846
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                  fill="white"
                />
                <path
                  d="M9.01908 11.8731V12.9959H12.1955V14.2097H9.01908V16H7.20191V14.2097H6V12.9959H7.20191V11.8731H6V10.28H7.20191V5H10.5215C13.1542 5 15 5.69793 15 8.42897C15 11.0386 13.1542 11.8731 10.5215 11.8731H9.01908ZM9.01908 10.28H10.593C11.9952 10.28 13.1256 9.97655 13.1256 8.42897C13.1256 6.89655 11.9952 6.60828 10.593 6.60828H9.01908V10.28Z"
                  fill="#E07031"
                />
              </svg>
            </div>
          </div>
          <div class="col price">
            <div class="articul planshet">Стоимость</div>
            <div class="price--calc">16 000 ₽</div>
          </div>
          <div class="col quantity">
            <div class="articul planshet">Количество</div>
            <div class="quantity--calc">
              <div class="quantity__btn" @click="decrement">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M2.9165 7H11.0832"
                    stroke="#111111"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="quantity__value">{{ quantity }}</div>
              <div class="quantity__btn" @click="increment">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M2.9165 6.99935H11.0832M6.99984 2.91602V11.0827"
                    stroke="#111111"
                    stroke-width="0.82"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="col last">
            <a href="" class="btn-black">В корзину</a>
            <a href="" class="btn-grey disainer">В проект</a>
          </div>
        </div>
      </div>
      <div class="accessories">
        <div class="head-h3">Аксессуары</div>
        <div class="accessories__items">
          <div class="product__calculation-info">
            <div class="image--calc">
              <img :src="gallery[0].src" alt="" />
            </div>
            <div class="col first">
              <div class="articul">Артикул: 1728507</div>
              <div class="product__name--calc">CM-PUNTO 50-6-940-24-</div>
              <div class="atribute-calc">Световой поток прибора: 250 лм</div>
            </div>
            <div class="col cashback">
              <div class="articul planshet">Кешбэк</div>
              <div class="designer_cashback">
                + 1 846
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="white"
                  />
                  <path
                    d="M9.01908 11.8731V12.9959H12.1955V14.2097H9.01908V16H7.20191V14.2097H6V12.9959H7.20191V11.8731H6V10.28H7.20191V5H10.5215C13.1542 5 15 5.69793 15 8.42897C15 11.0386 13.1542 11.8731 10.5215 11.8731H9.01908ZM9.01908 10.28H10.593C11.9952 10.28 13.1256 9.97655 13.1256 8.42897C13.1256 6.89655 11.9952 6.60828 10.593 6.60828H9.01908V10.28Z"
                    fill="#E07031"
                  />
                </svg>
              </div>
            </div>
            <div class="col price">
              <div class="articul planshet">Стоимость</div>
              <div class="price--calc">16 000 ₽</div>
            </div>
            <div class="col quantity">
              <div class="articul planshet">Количество</div>
              <div class="quantity--calc">
                <div class="quantity__btn" @click="decrement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M2.9165 7H11.0832"
                      stroke="#111111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="quantity__value">{{ quantity }}</div>
                <div class="quantity__btn" @click="increment">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M2.9165 6.99935H11.0832M6.99984 2.91602V11.0827"
                      stroke="#111111"
                      stroke-width="0.82"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="col last">
              <a href="" class="btn-black">В корзину</a>
              <a href="" class="btn-grey disainer">В проект</a>
            </div>
          </div>
          <span class="line"></span>
          <div class="product__calculation-info">
            <div class="image--calc">
              <img :src="gallery[0].src" alt="" />
            </div>
            <div class="col first">
              <div class="articul">Артикул: 1728507</div>
              <div class="product__name--calc">CM-PUNTO 50-6-940-24-</div>
              <div class="atribute-calc">Световой поток прибора: 250 лм</div>
            </div>
            <div class="col cashback">
              <div class="articul planshet">Кешбэк</div>
              <div class="designer_cashback">
                + 1 846
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="white"
                  />
                  <path
                    d="M9.01908 11.8731V12.9959H12.1955V14.2097H9.01908V16H7.20191V14.2097H6V12.9959H7.20191V11.8731H6V10.28H7.20191V5H10.5215C13.1542 5 15 5.69793 15 8.42897C15 11.0386 13.1542 11.8731 10.5215 11.8731H9.01908ZM9.01908 10.28H10.593C11.9952 10.28 13.1256 9.97655 13.1256 8.42897C13.1256 6.89655 11.9952 6.60828 10.593 6.60828H9.01908V10.28Z"
                    fill="#E07031"
                  />
                </svg>
              </div>
            </div>
            <div class="col price">
              <div class="articul planshet">Стоимость</div>
              <div class="price--calc">16 000 ₽</div>
            </div>
            <div class="col quantity">
              <div class="articul planshet">Количество</div>
              <div class="quantity--calc">
                <div class="quantity__btn" @click="decrement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M2.9165 7H11.0832"
                      stroke="#111111"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="quantity__value">{{ quantity }}</div>
                <div class="quantity__btn" @click="increment">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M2.9165 6.99935H11.0832M6.99984 2.91602V11.0827"
                      stroke="#111111"
                      stroke-width="0.82"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="col last">
              <a href="" class="btn-black">В корзину</a>
              <a href="" class="btn-grey disainer">В проект</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock></FooterBlock>
</template>
<style scoped>
.product__head {
  display: flex;
  gap: 22px;
}
.product__gallery {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
  flex-shrink: 0;
}
.accordion-enter-active,
.accordion-leave-active {
  transition: all 0.3s ease;
  max-height: 200px;
  opacity: 1;
}
.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.accordion .body {
  transition: 150ms ease-out;
}
.attribute__description {
  max-height: 200px;
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s ease;
}
.articul__name {
  color: #7c7c7c;
  font-family: "Suisse Intl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product__name {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 52px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.description {
  color: #282828;
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.attribute__title {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;
}
.attribute__description {
  transition: all 0.3s;
}
.attribute {
  padding: 17px 0;
  border-bottom: 1px solid #11111121;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.product__info {
  display: flex;
  flex-direction: column;
  gap: 36px;
  justify-content: space-between;
  height: 736px;
  max-height: 70vh;
  position: sticky;
  top: 17.5%;
}
.product__price {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.price {
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.image img {
  width: 100%;
  object-fit: cover;
}
.ps {
  color: var(--Black-600, #b0b0b0);
  font-family: "Suisse Intl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 6px;
}
.price__wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: auto;
}
.product__add {
  display: flex;
  align-items: center;
  gap: 10px;
}

.addToWishlist {
  border: 1px solid var(--Black, #111);
  padding: 19px;
  display: flex;
}
.product__variations {
  display: flex;
  flex-direction: column;
  gap: 52px;
  padding-top: 140px;
}
.product__variation {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
.product__variations-inputs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.variation {
  display: flex;
  gap: 50px;
  align-items: center;
}
.variation__title {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%;
  width: 122px;
  flex-shrink: 0;
}
.variation__inputs {
  display: flex;
  gap: 6px;
}
.radio {
  display: none;
}
.label {
  border: 1px solid var(--Black-300, #eaeaea);
  background: var(--Black-150, #f5f5f5);
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%;
  padding: 11px 14px;
  display: block;
}
.variation__input input:checked + .label {
  border: 1px solid var(--Black, #111);
  background: var(--Black-100, #f9f9f9);
}
.product__calculation {
  border-top: 1px solid #11111121;
  border-bottom: 1px solid #11111121;
  padding: 16px 0;
  margin-top: 82px;
}

.product__calculation-info {
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.4fr 0.4fr 0.4fr 1fr;
  gap: 46px;
}
.col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
.col.first {
  align-items: flex-start;
}
.col.last {
  flex-direction: row;
  align-items: center;
}
.image--calc {
  width: 116px;
  height: 116px;
}
.image--calc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.articul {
  color: var(--Black-900, #757575);
  font-family: "Suisse Intl";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: flex-start;
}
.product__name--calc {
  overflow: hidden;
  color: var(--Black, #111);
  text-overflow: ellipsis;
  font-family: "Suisse Intl";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.atribute-calc {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.quantity--calc {
  display: flex;
  gap: 20px;
  align-items: center;
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%;
}
.accessories {
  display: flex;
  flex-direction: column;
  gap: 78px;
  margin-top: 132px;
}
.accessories__items {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.line {
  width: 100%;
  height: 1px;
  background: #11111121;
}
.attributes {
  display: flex;
  gap: 6px 20px;
  flex-wrap: wrap;
}
.attributes__item {
  display: flex;
  width: calc(50% - 10px);
  align-items: end;
  gap: 6px;
}
.attributes__item p {
  white-space: nowrap;
  color: var(--Black-1000, #545454);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.attributes__item .naming {
  color: #2e2e2e;
}
.attributes__item span.line-item {
  width: 100%;
  height: 2px;
  align-self: flex-end;
  border-bottom: 2px dashed rgba(204, 204, 204, 1);
}
.price--calc {
  color: var(--Black, #111);

  /* Title/H8 22 reg */
  font-family: "Suisse Intl";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.quantity__btn {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.name {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
@media screen and (max-width: 1440px) {
  .product__gallery {
    max-width: 630px;
  }
  .product__gallery .image {
    max-height: 528px;
  }
  .product__gallery .image img {
    object-fit: cover;
    height: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .product__head {
    flex-direction: column;
    gap: 36px;
  }
  .swiper-slide {
    height: 528px;
    display: flex;
  }
  .swiper-slide img {
    width: 100%;
    object-fit: cover;
    object-position: center 67%;
  }
  .close {
    border-radius: 48px;
    background: var(--Black, #161616);
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .product__info {
    max-height: none;
    height: auto;
  }
  .price__wrapper {
    margin-top: 137px;
  }
  .product__name {
    font-size: 48px;
  }
  .title {
    font-size: 46px;
  }
  .product__variations {
    gap: 48px;
  }
  .product__variation {
    flex-direction: column;
    gap: 56px;
  }
  .product__calculation-info {
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 0 16px;
  }
  .image--calc {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 4;
    width: 100%;
    height: 100%;
  }
  .col.first {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .articul.planshet {
    display: none;
  }
  .col.last {
    grid-column-start: 5;
    grid-column-end: 11;
    grid-row-start: 4;
    grid-row-end: 4;
    justify-content: end;
  }
  .col.quantity {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .quantity--calc {
    justify-content: space-between;
  }
  .col.cashback {
    grid-column-start: 8;
    grid-column-end: 11;
    grid-row-start: 2;
    grid-row-end: 3;
    align-items: end;
    justify-content: flex-start;
  }
  .col.price {
    grid-column-start: 8;
    grid-column-end: 11;
    grid-row-start: 1;
    grid-row-end: 2;
    align-items: end;
  }
  .product__name--calc {
    font-size: 18px;
  }
  .col {
    gap: 6px;
  }
}
@media screen and (max-width: 650px) {
  .swiper-slide {
    height: 261px;
  }
  .product__name {
    font-size: 26px;
  }
  .description,
  .attribute__title,
  .attributes__item p {
    font-size: 13px;
  }
  .name {
    gap: 10px;
  }
  .price__wrapper {
    margin-top: 32px;
  }
  .price {
    gap: 10px;
    font-size: 23px;
  }
  .modificationBtn,
  .variation__title,
  .label {
    font-size: 14px;
  }
  .addToWishlist {
    padding: 17px;
  }
  .title {
    font-size: 32px;
  }
  .product__variations {
    gap: 36px;
  }
  .variation {
    gap: 26px;
  }
  .variation__inputs {
    flex-wrap: wrap;
  }
  .product__variation {
    gap: 52px;
  }
  .product__name--calc,
  .price--calc {
    font-size: 14px;
  }
  .articul {
    font-size: 10px;
  }
  .atribute-calc {
    display: none;
  }
  .image--calc {
    width: 80px;
    height: 80px;
  }
  .product__calculation-info {
    grid-template-rows: repeat(4, 31px);
  }
  .col.first {
    grid-column-start: 3;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .quantity--calc {
    gap: 10px;
  }
  .quantity__btn {
    width: 26px;
    height: 26px;
    padding: 6px;
  }
  .col.last {
    grid-column-start: 4;
  }
}
</style>