<script>
// import axios from "axios";
// import Cookies from "js-cookie";
import HeaderBlock from "../block/HeaderBlock.vue";
import ProjectBlockAccVue from '../block/ProjectBlockAcc.vue';
import ProjectsBlockAccVue from "../block/ProjectsBlockAcc.vue";
import WishlistBlockAccVue from '../block/WishlistBlockAcc.vue';


export default {
  name: "AccountPage",
  components: {
    HeaderBlock,
    ProjectsBlockAccVue,
    WishlistBlockAccVue,
    ProjectBlockAccVue
  },
  props: {
    msg: String,
    links: String,
  },
  data() {
    return {
      blocks: [
        {
          id: 1,
          status: false,
          className: "projects",
        },
        {
          id: 2,
          status: false,
          className: "wishlist",
        },
        {
          id: 3,
          status: false,
          className: "history",
        },
        {
          id: 4,
          status: false,
          className: "accountSetting",
        },
        {
          id: 5,
          status: false,
          className: "menedger",
        },
        {
          id: 6,
          status: false,
          className: "project",
        },
      ],
      // NewProject: {},
      // ВПЕМЕННО
      NewProject: {
        name: " Новый проект",
        created_at: "",
        updated_at: "",
        price: "",
        status: "",
        
        room: [
          {
            id: 1,
            name: "Спальная",
            products: [3, 17, 21],
          },
          {
            id: 2,
            name: "Гардеробная",
            products: [24, 25, 26],
          },
          {
            id: 3,
            name: "Кухня",
            products: [3, 17, 21],
          },
          {
            id: 4,
            name: "Ванная",
            products: [3, 17, 21],
          },
          {
            id: 5,
            name: "Санузел",
          },
          {
            id: 6,
            name: "Балкон",
          },
          {
            id: 7,
            name: "Туалет",
          },
          {
            id: 8,
            name: "Другое",
          },

          {
            id: 9,
            name: "Спальная",
          },
          {
            id: 10,
            name: "Гардеробная",
          },
          {
            id: 11,
            name: "Кухня",
          },
          {
            id: 12,
            name: "Ванная",
          },
        ],
      },
    };
  },
  methods: {
    toogleActive(className) {
      for (let i of this.blocks) {
        i.status = false;
      }
      let items = document.querySelectorAll(".nav__item");
      for (let i of items) {
        i.classList.remove("active");
      }
      for (let i of items) {
        if (i.classList.contains(className)) {
          i.classList.add("active");
        }
      }

      for (let i of this.blocks) {
        if (i.className === className) {
          i.status = true;
        }
      }
    },
    createProject(project) {
      this.NewProject = project;
      this.toogleActive("project");
    },
  },
  mounted() {
    this.toogleActive("project");
  },
};
</script>

<template>
  <HeaderBlock :className="'white'"></HeaderBlock>
  <div class="account">
    <div class="container containerAcc">
      <div class="account__head">
        <h2 class="acc__title">Личный кабинет</h2>
        <div class="acc_id">
          <p>Персональный ID: 38647</p>
        </div>
      </div>
      <div class="account__content">
        <div class="acc-sidebar">
          <div class="acc-col">
            <div class="avatar">
              <img src="../../assets/image/avatar.png" alt="" />
            </div>
            <div class="acc_title">
              <div class="name">Алексей Петров</div>
              <div class="sfera">Светодизайнер</div>
            </div>
          </div>
          <div class="acc-col col">
            <div class="percent">
              <div class="logo">
                <img src="../../assets/image/logo-acc.svg" alt="" />
              </div>
              <div class="percent__text">
                Процент скидки:
                <span>10%</span>
              </div>
            </div>
            <span class="line"></span>
            <div class="cashback">
              <div class="label">Кешбэк:</div>
              <div class="designer_cashback">
                + 1 846
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
                    fill="white"
                  />
                  <path
                    d="M9.01908 11.8731V12.9959H12.1955V14.2097H9.01908V16H7.20191V14.2097H6V12.9959H7.20191V11.8731H6V10.28H7.20191V5H10.5215C13.1542 5 15 5.69793 15 8.42897C15 11.0386 13.1542 11.8731 10.5215 11.8731H9.01908ZM9.01908 10.28H10.593C11.9952 10.28 13.1256 9.97655 13.1256 8.42897C13.1256 6.89655 11.9952 6.60828 10.593 6.60828H9.01908V10.28Z"
                    fill="#E07031"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="nav">
            <div @click="toogleActive('projects')" class="nav__item projects">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M13 11.0244V16.7597M10.075 13.8921H15.925M20.8 20.5833C21.3172 20.5833 21.8132 20.3818 22.1789 20.0233C22.5446 19.6648 22.75 19.1785 22.75 18.6715V9.11266C22.75 8.60563 22.5446 8.11937 22.1789 7.76084C21.8132 7.40232 21.3172 7.2009 20.8 7.2009H13.0975C12.7714 7.20403 12.4497 7.12692 12.1618 6.97663C11.8739 6.82633 11.6291 6.60765 11.4498 6.3406L10.66 5.19355C10.4824 4.92922 10.2407 4.71224 9.95654 4.56209C9.67235 4.41193 9.35458 4.3333 9.03175 4.33325H5.2C4.68283 4.33325 4.18684 4.53467 3.82114 4.89319C3.45545 5.25172 3.25 5.73799 3.25 6.24502V18.6715C3.25 19.1785 3.45545 19.6648 3.82114 20.0233C4.18684 20.3818 4.68283 20.5833 5.2 20.5833H20.8Z"
                  stroke="#2E2E2E"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Проекты
            </div>
            <div @click="toogleActive('wishlist')" class="nav__item wishlist">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M19.825 14.9261C21.2778 13.5202 22.75 11.835 22.75 9.62979C22.75 8.22513 22.185 6.87799 21.1794 5.88475C20.1737 4.8915 18.8097 4.3335 17.3875 4.3335C15.6715 4.3335 14.4625 4.81498 13 6.25942C11.5375 4.81498 10.3285 4.3335 8.6125 4.3335C7.19028 4.3335 5.8263 4.8915 4.82064 5.88475C3.81498 6.87799 3.25 8.22513 3.25 9.62979C3.25 11.8446 4.7125 13.5298 6.175 14.9261L13 21.6668L19.825 14.9261Z"
                  stroke="#2E2E2E"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Избранное
            </div>
            <div @click="toogleActive('history')" class="nav__item history">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M4.33301 13.0002C4.33301 14.7143 4.8413 16.3899 5.7936 17.8151C6.74591 19.2403 8.09946 20.3512 9.68308 21.0071C11.2667 21.6631 13.0093 21.8347 14.6905 21.5003C16.3716 21.1659 17.9159 20.3405 19.1279 19.1284C20.34 17.9164 21.1654 16.3721 21.4998 14.6909C21.8342 13.0098 21.6626 11.2672 21.0066 9.68357C20.3507 8.09995 19.2398 6.7464 17.8146 5.79409C16.3894 4.84179 14.7138 4.3335 12.9997 4.3335C10.5768 4.34261 8.25127 5.28801 6.5093 6.97201L4.33301 9.14831M4.33301 9.14831V4.3335M4.33301 9.14831H9.14782M12.9997 8.18535V13.0002L16.8515 14.9261"
                  stroke="#2E2E2E"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              История заказов
            </div>
            <div @click="toogleActive('menedger')" class="nav__item menedger">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M4.33301 14.9261H7.2219C7.73268 14.9261 8.22255 15.129 8.58373 15.4902C8.94491 15.8514 9.14782 16.3412 9.14782 16.852V19.7409C9.14782 20.2517 8.94491 20.7416 8.58373 21.1027C8.22255 21.4639 7.73268 21.6668 7.2219 21.6668H6.25893C5.74815 21.6668 5.25828 21.4639 4.8971 21.1027C4.53592 20.7416 4.33301 20.2517 4.33301 19.7409V13.0002C4.33301 10.7016 5.2461 8.49722 6.87142 6.8719C8.49673 5.24659 10.7011 4.3335 12.9997 4.3335C15.2982 4.3335 17.5026 5.24659 19.1279 6.8719C20.7532 8.49722 21.6663 10.7016 21.6663 13.0002V19.7409C21.6663 20.2517 21.4634 20.7416 21.1022 21.1027C20.7411 21.4639 20.2512 21.6668 19.7404 21.6668H18.7775C18.2667 21.6668 17.7768 21.4639 17.4156 21.1027C17.0544 20.7416 16.8515 20.2517 16.8515 19.7409V16.852C16.8515 16.3412 17.0544 15.8514 17.4156 15.4902C17.7768 15.129 18.2667 14.9261 18.7775 14.9261H21.6663"
                  stroke="#2E2E2E"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Персональный менеджер
            </div>
            <div @click="toogleActive('accountSetting')" class="nav__item accountSetting">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M13.0003 13.9631C15.6179 13.9631 17.7399 11.8075 17.7399 9.14831C17.7399 6.48916 15.6179 4.3335 13.0003 4.3335C10.3827 4.3335 8.26074 6.48916 8.26074 9.14831C8.26074 11.8075 10.3827 13.9631 13.0003 13.9631ZM13.0003 13.9631C15.0116 13.9631 16.9404 14.7748 18.3626 16.2195C19.7847 17.6642 20.5837 19.6237 20.5837 21.6668M13.0003 13.9631C10.9891 13.9631 9.06025 14.7748 7.6381 16.2195C6.21595 17.6642 5.41699 19.6237 5.41699 21.6668"
                  stroke="#2E2E2E"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Учетная запись
            </div>
          </div>
        </div>
        <div class="content">
          <projects-block-acc-vue
            v-if="blocks[0].status"
            @createProject="createProject"
          ></projects-block-acc-vue>
          <wishlist-block-acc-vue
            v-if="blocks[1].status" 
          ></wishlist-block-acc-vue>
          <project-block-acc-vue
          v-if="blocks[5].status"
          :project="NewProject"
          >
          </project-block-acc-vue>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.account {
  padding: 108px 0;
}
.containerAcc{
  max-width: 1760px;
}
.account__head {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.acc_id {
  color: #848484;
  font-family: "Suisse Intl";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.18px;
}
.acc__title {
  color: var(--black-p-process-black-c, #1d1d1b);
  font-family: "Suisse Intl";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -0.36px;
}
.account__content {
  display: flex;
  gap: 80px;
  margin-top: 32px;
}
.acc-sidebar {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.acc-col {
  border-radius: 2px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(21, 30, 45, 0.05);
  padding: 10px 16px 16px;
  min-height: 78px;
  display: flex;
  align-items: center;
  gap: 14px;
  width: 280px;
}
.acc-col.col {
  flex-direction: column;
  gap: 14px;
}
.name {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 122%;
}
.sfera {
  color: #3e3e3e;
  font-family: "Suisse Intl";
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.percent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.percent__text {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: right;
  color: #777;
  text-align: right;
  font-family: "Suisse Intl";
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.percent__text span {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: -0.18px;
}
.designer_cashback {
  display: flex !important;
}
.cashback {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.line {
  background: #e3e3e3;
  width: 100%;
  height: 1px;
}
.label {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 2px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 6px 0px rgba(21, 30, 45, 0.05);
  padding: 6px 0;
}
.nav__item {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
}
.nav__item.active {
  border-radius: 2px;
  background: var(--Black-1400, #111);
  color: var(--White, #fff);
}
.nav__item.active svg path {
  stroke: var(--White, #fff);
}
.content {
  width: 100%;
  max-width: 1320px;
}
</style>