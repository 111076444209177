import Main from '@/components/pages/HomePage.vue';
import Shop from '@/components/pages/ShopPage.vue';
import TrainingPage from '@/components/pages/TrainingPage.vue';
import TrainingPostPage from '@/components/pages/TrainingPostPage.vue';
import NewsPage from '@/components/pages/NewsPage.vue';
import PostPage from '@/components/pages/PostPage.vue';
import ProductPage from '@/components/pages/ProductPage.vue';
import LoginPage from '@/components/pages/LoginPage.vue';
import AccountPage from '@/components/pages/AccountPage.vue';

import { createRouter, createWebHistory } from 'vue-router';


/**
 * Страница получающая массив анкет из vuex
 */

import AboutPage from '@/components/pages/AboutPage.vue';


const routes = [
  //Для всех
  {
    path: '/',
    component: Main,
  },
  {
    path: '/about',
    component: AboutPage,
  },
  {
    path: '/news',
    component: NewsPage,
  },
  {
    path: '/training',
    component: TrainingPage,
  },
  {
    path: '/shop',
    component: Shop,
  },
  {
    path: '/training-post',
    component: TrainingPostPage,
  },
  {
    path: '/post',
    component: PostPage,
  },
  {
    path: '/product/:id',
    name: 'ProductPage',
    component: ProductPage
  },
  {
    path: '/login',
    component: LoginPage
  },
  {
    path: '/account',
    component: AccountPage
  },


]

const router = createRouter({
  mode: 'history',
  routes,
  history: createWebHistory(),
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})


export default router;
