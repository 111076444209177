<script>

export default {
  name: "SearcheLink",
  props: {
    className: {
      type: String,
      required: true
    },
  }
}
</script>
<template>
  <div :class="className + ' search-icon'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 21L16.7 16.7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style scoped>
.black path{
    stroke: white;
}
.white path{
    stroke: black;
}
@media screen and (max-width: 650px) {
  .search-icon{
    display: none;
  }
}
</style>