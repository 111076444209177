<script>
import axios from "axios";
import Cookies from "js-cookie";
export default {
  name: "LoginPage",

  components: {},
  props: {
    msg: String,
    links: String,
  },
  methods: {
    userRegister() {
      this.error = "";
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      let params = {
        username: this.inputUsername,
        password: this.inputPassword,
        i: this.inputI,
        email: this.inputEmail,
        tel: this.inputUsername,
      };
      console.log(params);
      axios
        .post(this.apiUrl + "api/user/register" + authGet, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log("userRegister", response);
          if (response.data.status) {
            this.$store.commit("setUserData", response.data.user);
            this.$emit("registerSuccess");
          } else {
            this.error = response.data.error;
          }
        });
    },
    userLogin() {
      console.log("userLogin");
      this.error = "";
      let authGet = `&auth=${this.user.username}:${this.user.auth_key}`;
      this.inputUsername = document.querySelector(".loginInput").value.replace(
        /\D/g,
      );
      this.inputPassword = document.querySelector(".passInput").value;
      let params = {
        username: this.inputUsername,
        password: this.inputPassword,
      };
      console.log(params);
      axios
        .post(this.apiUrl + "api/user/login" + authGet, params, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
            console.log("userLogin", response);
          if (response.data.status) {
            this.$store.commit("setUserData", response.data.user);
            let jsonUser = JSON.stringify(response.data.user);
            Cookies.set("user", jsonUser, { expires: 7 });
            this.$emit("registerSuccess");
            window.location.href = "/account";
          } else {
            this.error = response.data.error;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    },
    onEmailChange() {
      let email = document.querySelector(".emailChange");
      if (email.value) {
        let emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailPattern.test(email.value)) {
          console.log("Email is valid");
          document.querySelector(".emailValid").classList.remove("default");
        } else {
          console.log("Email is invalid");
          document.querySelector(".emailValid").classList.add("default");
        }
      }
    },
    popupToogle() {
      document.querySelector(".popup").classList.toggle("active");
    },
    switchTo(mess) {
      this.message = mess;
      console.log("switchToRegistration", this.msg);
    },

    loginRequest() {
      let login = document.querySelector(".inputLogin");

      this.login = login.value;
    },
    PassRequest() {
      let pass = document.querySelector(".inputPass");

      this.pass = pass.value;
    },
    accountRequest() {
      console.log("accountRequest", this.login, this.pass);
      if (this.login !== "" && this.pass !== "") {
        if (this.login === "admin" && this.pass === "adminadmin") {
          window.location.href = "/account";
        }
      }
    },
  },
  data() {
    return {
      message: "login",
      login: "",
      pass: "",
      inputUsername: "",
      inputPassword: "",
      inputI: "",
      inputEmail: "",
      sfera: "",
      error: "",
      jsonUser: "",
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
    };
  },
  mounted() {
    this.switchTo(this.message);
  },
};
</script>

<template>
  <div class="login">
    <div class="login__wrapper">
      <div class="login__content">
        <div class="login__head">
          <a href="/" class="logo">
            <img
              class="logo-image"
              src="@/assets/image/logo-black.svg"
              alt=""
            />
          </a>
          <a href="/shop" class="back">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M11 17.4844L4.51819 11.0026M4.51819 11.0026L11 4.52075M4.51819 11.0026L17.4818 11.0026"
                stroke="#2E2E2E"
                stroke-width="1.3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            Вернуться к покупкам
          </a>
        </div>
        <div v-if="message == 'login'" key="login" class="form login__form">
          <div class="head-h3">Вход</div>

          <div class="form__content">
            <div class="form__item">
              <label class="form__label">Электронная почта*</label>
              <input type="text" class="form__input loginInput"  readonly
                  onfocus="this.removeAttribute('readonly')"/>
            </div>
            <div class="form__item">
              <label class="form__label">Пароль</label>
              <input type="password" class="form__input passInput"  readonly
                  onfocus="this.removeAttribute('readonly')"/>
              <a @click="popupToogle" class="forgot">Забыли пароль?</a>
            </div>
          </div>
          <div class="form__footer">
            <button @click="userLogin" class="btn btn-black">Вход</button>
          </div>
          <div class="form__footer">
            <a @click="switchTo('registration')" class="link"
              >Впервые здесь? <span>Зарегистрироваться</span></a
            >
          </div>
        </div>
        <div v-if="message == 'registration'" class="form registrtion__form">
          <div class="head-h3">Регистрация</div>
          <div class="form__content">
            <div class="form__row">
              <div class="form__item">
                <label class="form__label">Имя *</label>
                <input v-model="inputI" type="text" class="form__input" />
              </div>
              <div class="form__item">
                <label class="form__label ">Номер телефона *</label>
                <input
                  type="text"
                  v-model="inputUsername"
                  class="form__input phone-input"
                  readonly
                  onfocus="this.removeAttribute('readonly')"
                />
              </div>
            </div>
            <div class="form__item">
              <label class="form__label">Электронная почта *</label>
              <input
                type="text"
                v-model="inputEmail"
                class="form__input"
                readonly
                onfocus="this.removeAttribute('readonly')"
              />
            </div>
            <div class="form__item">
              <label class="form__label">Сфера деятельности</label>
              <input type="text" v-model="sfera" class="form__input" />
            </div>
            <div class="form__row">
              <div class="form__item">
                <label class="form__label passRegInput">Пароль *</label>
                <input
                  type="password"
                  v-model="inputPassword"
                  class="form__input"
                  readonly
                  onfocus="this.removeAttribute('readonly')"
                />
              </div>
              <div class="form__item">
                <label class="form__label">Подтверждение пароля *</label>
                <input
                  type="password"
                  class="form__input"
                  readonly
                  onfocus="this.removeAttribute('readonly')"
                />
              </div>
            </div>
            <div class="form__footer">
              <button @click="userRegister" class="btn btn-black">Регистрация</button>
            </div>
            <div class="form__footer">
              <a @click="switchTo('login')" class="link"
                >Уже есть аккаунт? <span>Войти</span></a
              >
            </div>
          </div>
        </div>
        <div class="politic">
          <div v-if="message == 'registration'" class="politic__text">
            Нажимая кнопку «Зарегистрироваться», я даю согласие на обработку
            персональных данных, в соответствии с <span>Политикой</span>, и
            соглашаюсь с <span>Правилами</span>
          </div>
        </div>
      </div>
    </div>
    <div class="login__image">
      <img
        class="login-image"
        src="@/assets/image/login-bg.png"
        alt="login-image"
      />
    </div>
  </div>
  <div class="popup">
    <div class="popup__content">
      <a @click="popupToogle" class="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M18 6L6 18M6 6L18 18"
            stroke="#545454"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a>
      <div class="popup__head">Восстановление пароля</div>
      <div class="form__item">
        <label class="form__label">Электронная почта</label>
        <input
          @input="onEmailChange"
          type="text"
          class="form__input emailChange"
        />
      </div>
      <button class="btn btn-black default emailValid">Восстановить</button>
    </div>
  </div>
</template>

<style scoped>
.close{
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}
.login {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
.login__content {
  width: 536px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  padding: 22px 0;
  height: 100%;
}
.login__head {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.logo {
  max-width: 205px;
}
.back {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  max-width: 536px;
}
.form__item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 536px;
}
.form__label {
  color: var(--Black-1300, #1d1d1b);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
}
.form__input,
.form__input:autofill,
.form__input:-webkit-autofill,
.form__input:-internal-autofill-selected {
  border-radius: 2px;
  background: var(--Black-200, #f2f2f2);
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%;
  padding: 21px 16px;
  outline: none;
  border: none;
}
.form__input:hover,
.form__input:focus {
  border-radius: 2px;
  background: var(--Black-300, #eaeaea);
  list-style: none;
  outline: none;
}
.form__content {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.form__row {
  display: flex;
  gap: 22px;
  justify-content: space-between;
}
.forgot {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 8px;
}
.btn-black {
  width: 100%;
}
.link {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.link span {
  text-decoration-line: underline;
}
.login__image {
  width: 50%;
  flex-shrink: 0;
  height: 100%;
}
.login__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  flex-shrink: 0;
  padding-right: 13%;
  padding-left: 40px;
  height: 100%;
}
.login-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.politic__text {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.politic__text span {
  text-decoration-line: underline;
}
.popup {
  background: rgba(31, 31, 31, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.popup.active {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}
.popup__content {
  border-radius: 2px;
  background: var(--White, #fff);
  padding: 40px 36px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 33px;
  max-width: 586px;
  width: 100%;
}
.popup__head {
  color: var(--Black-1300, #1d1d1b);
  text-align: center;
  font-family: "Suisse Intl";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}
</style>