<script>
import SearcheLink from "../ui/SearcheLink.vue";
import BasketLink from "../ui/basketLink.vue";
import AccountLink from "../ui/accountLink.vue";
import burgerLinkVue from "../ui/burgerLink.vue";
export default {
  name: "HeaderBlock",
  components: {
    SearcheLink,
    BasketLink,
    AccountLink,
    burgerLinkVue,
  },
  props: {
    className: String,
  },
  data() {
    return {
      kategoryes: [
        {
          title: "Трековые",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Подвесные",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Встраиваемые",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Накладные",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Светодиодные ленты",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Блоки питания",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Настольные",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Уличные",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
        {
          title: "Lucius",
          link: "/shop",
          img: "../../assets/image/kategory.png",
        },
      ],
    };
  },
  methods: {
    openKategoryes() {
      let kategoryes = document.querySelector(".kategoryes");
      kategoryes.classList.toggle("active");
    },
    headerMax() {
      let header = document.querySelector(".header");
      header.classList.toggle("max");
    },
  },
};
</script>

<template>
  <div :class="'header ' + className">
    <div class="container">
      <div class="header__container">
        <div class="header__content">
          <a href="/" class="logoLink">
            <img class="logo-white" src="../../assets/image/logo-white.svg" alt="" />
            <img
              class="logo-black"
              src="../../assets/image/logo-black.svg"
              alt=""
            />
          </a>
          <div class="nav-links">
            <searche-link :className="className" />
            <basket-link :className="className" />
            <account-link :className="className" />
            <burger-link-vue
              @openKategoryes="headerMax"
              :className="className"
            />
          </div>
        </div>
        <div class="header__links">
          <div class="links">
            <a @click="openKategoryes" class="link link-products"
              >Продукция
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M6 4L10.6667 8.66667L6 13.3333"
                  stroke="white"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path d="M11 8.67188H10" stroke="white" stroke-width="1.6" />
              </svg>
            </a>
            <a href="/training" class="link">Обучение</a>
            <a href="/news" class="link">Новости</a>
            <a href="/about" class="link">О компании</a>
          </div>
          <div class="adress">
            ул. Красных Партизан, 98
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M2 7.33334L14.6667 1.33334L8.66667 14L7.33333 8.66668L2 7.33334Z"
                stroke="white"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="kategoryes">
          <ul class="list">
            <li
              class="li__item"
              v-for="kategory in kategoryes"
              :key="kategory.id"
              :data-banner="kategory.img"
            >
              <a :href="kategory.link" class="li__link"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="17"
                  viewBox="0 0 25 17"
                  fill="none"
                >
                  <path
                    d="M15 4.33203L19.6667 8.9987L15 13.6654"
                    stroke="#858585"
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    class="path-stroke"
                    d="M19 9L3 9"
                    stroke="#858585"
                    stroke-width="1.6"
                  />
                </svg>
                {{ kategory.title }}</a
              >
            </li>
          </ul>
          <div class="banner">
            <img src="" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header.white .logo-white {
  display: none;
}
.header.black .logo-black {
  display: none;
}
.kategoryes {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.kategoryes.active {
  height: max-content;
  opacity: 1;
  transition: all 0.3s;
}

.kategoryes.active .list {
  opacity: 1;
  visibility: visible;
}
.link {
  color: var(--White, #fff);

  /* Body/Body 18 book */
  font-family: "Suisse Intl";
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0;
  transition: all 0.1s;
}
.header.white .link {
  color: #000;
}
.header__container {
  display: flex;
  flex-direction: column;
}
.header__links {
  display: flex;
  justify-content: space-between;
  gap: 36px;
  align-items: center;
  padding: 26px 0 29px;
  transform: translateY(-100%);
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}
.links {
  display: flex;
  gap: 36px;
}
.adress {
  color: var(--White, #fff);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  display: flex;
  gap: 12px;
  align-items: center;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}
.header.white .adress {
  color: #000;
}
.header.white path{
  stroke: #000;
}
.header {
  background: rgba(17, 17, 17, 0.18);
  backdrop-filter: blur(17px);
  padding-top: 22px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 73px;
  overflow: hidden;


}

.header__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nav-links {
  display: flex;
  flex-direction: row;
  gap: 18px;
}
.li__link {
  display: flex;
  align-items: center;
  gap: 6px;
}
.link-products svg{
  display: none;
}
@media screen and (min-width: 1028px) {
  .header:hover .link {
    opacity: 1;
    transition: all 0.3s;
  }
  .header:hover .header__links,
  .header:hover .adress {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .header:hover {
    height: max-content;
  }
}
@media screen and (max-width: 1024px) {
  .header {
    padding: 22px 0;
  }
  .header.max .link {
    opacity: 1;
    transition: all 0.3s;
  }
  .header.max .header__links,
  .header.max .adress {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
  .header.max {
    height: max-content;
  }
  .link,
  .li__link {
    font-size: 16px;
  }
  .links {
    gap: 28px;
  }
}
@media screen and (max-width: 650px) {
  .header__links {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .links {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
  .link {
    padding: 14px 3px;
    font-size: 22px;
  }
  .link-products svg{
    display: block;
  }
  .link-products{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>