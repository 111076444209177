<script>
export default {
  name: "TrainingBannerBlock",
  components: {

  },
};
</script>

<template>
  <section class="training__banner">
    <div class="container">
      <div class="training__banner-content">
        <div class="training__banner-text">
          <h2>Выбирайте светильники правильно с Lumatica</h2>
          <p>В нашем руководстве вы найдете все необходимые знания о выборе светильников различных типов. Наши эксперты подготовили для вас подробные материалы, которые помогут вам правильно подобрать освещение для вашего пространства.</p>
        </div>
        <div class="training__banner-img">
          <img src="@/assets/image/training/training-banner.png" alt="">
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped>
.training__banner {
  margin-bottom: 140px;
}
.training__banner-content {
  display: flex;
  background: #F2F2F2;
  padding: 88px 64px;
  position: relative;
}
.training__banner-text {
  max-width: 692px;
  color:#2E2E2E;
  font-family: Suisse Intl;
}
.training__banner-text h2 {
  font-size: 52px;
  line-height: 59px;
  margin-bottom: 20px;
  font-weight: 450;
  
}
.training__banner-text p {
  font-size: 16px;
  line-height: 18.53px;
}
.training__banner-img {
  position: absolute;
  right: 127px;
  top: 0;
}
@media (max-width: 1440px) {
  .training__banner-content {
    padding: 88px 56px;
  }
  .training__banner-img {
    right: 27px;
  }
  .training__banner {
    margin-bottom: 120px;
  }
}
@media (max-width: 1200px) {
  .training__banner-content {
    overflow: hidden;
    padding: 34px 28px;
  }
  .training__banner-img {
    right: 27px;
  }
  .training__banner-text {
    max-width: 522px;
  }
  .training__banner-text h2 {
    font-size: 40px;
    line-height: 45px;
  }
  .training__banner-text p {
    font-size: 14px;
    line-height: 16.52px;
  }
  .training__banner-img {
    right: -33px;
        width: 335px;
  }
  
}
@media (max-width: 768px) {
  .training__banner-text h2 {
    font-size: 30px;
    line-height: 34.74px;
  }
  .training__banner-text {
    max-width: 61%;
  }
}
@media (max-width: 575px) {
  .training__banner {
    margin-bottom: 60px;
  }
  .training__banner-img {
    right: -283px;
    width: 375px;
  }
  .training__banner-text {
    max-width: 91%;
  }
  .training__banner-text h2 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 22px;
  }
  .training__banner-text p {
    font-size: 12px;
    line-height: 13.9px;
  }
  .training__banner-content {
    padding: 29px 16px;
  }
}
</style>