<script>

export default {
    name: "WishlistBlockAcc",

}
</script>

<template> 
    <div class="wishlist"></div>
</template>