<script>
import HeaderBlock from "../block/HeaderBlock.vue";
import TrainingBannerBlock from "../block/TrainingBannerBlock.vue";
import FooterBlock from "../block/FooterBlock.vue";
import BreadcrumbsUi from "../ui/Breadcrumbs.vue";
import TrainingSectionsBlock from "../block/TrainingSectionsBlock.vue";

export default {
    name: "TrainingPage",
    components: {
        HeaderBlock,
        BreadcrumbsUi,
        TrainingBannerBlock,
        TrainingSectionsBlock,
        FooterBlock
    },
}
</script>

<template>
    <HeaderBlock :className="'white'"></HeaderBlock>
    <BreadcrumbsUi></BreadcrumbsUi>
    <TrainingBannerBlock></TrainingBannerBlock>
    <TrainingSectionsBlock></TrainingSectionsBlock>
    <FooterBlock></FooterBlock>
</template>