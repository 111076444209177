<script>
export default {
  name: "AccountLink",
  props: {
    className: {
      type: String,
      required: true,
    },
  },
  methods: {
    openKategoryes() {
      this.$emit("openKategoryes");
    },
  },
};
</script>
<template>
  <div @click="openKategoryes" :class="className + ' account-icon burger'">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3 12H21"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 6H21"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3 18H21"
        stroke="white"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<style scoped>
.black path {
  stroke: white;
}
.white path {
  stroke: black;
}
.burger{
    display: none;
}

@media (max-width: 1024px) {
    .burger{
        display: block;
    }
}
</style>