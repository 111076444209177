<script>
import BreadcrumbsVue from "../ui/Breadcrumbs.vue";
export default {
  name: "PostContentBlock",
  components: {
    BreadcrumbsVue,
  },
  data() {
    return {};
  },
};
</script>

<template>
  <div class="post">
    <div class="container">
      <div class="post__head">
        <BreadcrumbsVue />
        <div class="date date-mobile">15.01.2022</div>
      </div>
      <div class="post-content">
        <div class="post-content__head">
          <div class="col-24">
            <div class="title-post">
              Открытие современного складского комлекса
            </div>
            <div class="date">15.01.2022</div>
          </div>
          <div class="text text-head">
            Мы рады объявить о грандиозном событии в жизни Lumatica — открытии
            современного складского комплекса! Наш новый склад оборудован по
            последнему слову техники и спроектирован с учетом всех современных
            стандартов эффективного хранения и логистики. Этот современный
            комплекс предоставит нам возможность еще более оперативно и
            эффективно обслуживать наших клиентов. <br /><br />

            Благодаря новым технологиям и процессам управления складом, мы
            сможем обеспечить более быструю обработку заказов и оперативную
            доставку наших премиальных светильников. Открытие этого склада — это
            важный шаг в нашем стремлении обеспечить наших клиентов высочайшим
            уровнем обслуживания и качества. Мы уверены, что современный
            складской комплекс станет надежной основой для дальнейшего развития
            и успешного роста нашей компании. Благодарим всех наших партнеров и
            клиентов за поддержку.
          </div>
        </div>
        <div class="post-content__body">
          <div class="images two">
            <img src="@/assets/image/post1.png" alt="" />
            <img src="@/assets/image/post2.png" alt="" />
          </div>
          <div class="text left">
            Наш новый складской комплекс обладает рядом преимуществ, которые
            позволят нам еще более эффективно управлять нашими запасами и
            обеспечивать высокий уровень обслуживания для наших клиентов. Среди
            ключевых особенностей: Современное оборудование, автоматизированные
            системы управления, оптимизированные процессы обработки заказов.
            Благодаря которым мы можем обеспечить быструю и точную комплектацию
            и отгрузку товаров.
          </div>
          <div class="images one">
            <img src="@/assets/image/post3.png" alt="" />
          </div>
          <div class="text right">
            Процесс открытия современного складского комплекса для компании
            Lumatica был результатом длительной и тщательной подготовки.
            Начальный этап включал в себя определение потребностей компании в
            новом складе и разработку концепции проекта. Затем мы провели поиск
            подходящего местоположения, проведя анализ рынка и выбрав место,
            соответствующее нашим требованиям. После этого мы приступили к
            проектированию и строительству складского комплекса, где наши
            инженеры и дизайнеры работали над разработкой оптимальной
            планировки, выбором современного оборудования и применением
            экологически устойчивых решений. После завершения строительства мы
            внедрили необходимые технологии и оборудование, чтобы обеспечить
            эффективную работу склада и оптимизировать процессы хранения и
            отгрузки товаров.
          </div>
          <div class="images three">
            <img src="@/assets/image/post1.png" alt="" />
            <img src="@/assets/image/post2.png" alt="" />
            <img src="@/assets/image/post3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.post__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date {
  color: var(--Black-800, #858585);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
.date-mobile {
  display: none;
}
.post-content__head {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.title-post {
  max-width: 821px;
}
.text-head {
  max-width: 500px;
}
.text {
  color: var(--Black-1200, #2e2e2e);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%;
}
.post-content__body {
  display: flex;
  flex-direction: column;
  gap: 68px;
  margin-top: 68px;
}
.images {
  display: flex;
  gap: 20px;
}
.images img{
  width: 100%;
  min-height: 100%;
}
.images.three img {
  max-width: 33%;
}
.images.two img:first-child {
  max-width: calc(40% - 10px);
}
.images.two img:last-child {
  max-width: calc(60% - 10px);
}
.text.left{
  text-align: left;
  max-width: 622px;
}
.text.right{
  max-width: 878px;
  align-self: flex-end;
}
@media screen and (max-width: 1440px) {
  .text.right{
    max-width: 738px;
  }
}
@media screen and (max-width: 990px) {
  .post-content__head{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
  }
  .date{
    display: none;
  }
  .date-mobile{
    display: block;
    padding-top: 105px;
    margin-bottom: 48px;
  }
  .images{
    gap: 12px;
  }
  
}
@media screen and (max-width: 550px) {
    .text{
        font-size: 15px;
    }
    .post-content__body{
      gap: 40px;
      margin-top: 40px;
    }
    .post-content__head{
      gap: 28px;
    }
}
</style>