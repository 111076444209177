
<script>


export default {
  name: "App",
  components: {
   
  },
  data() {
    return {
      
    };
  }
};
</script>

<template>
  <router-view></router-view>  
</template>

<style>

</style>
