<script>
import VueSlider from "vue-3-slider-component";
import { ref } from "vue";
export default {
  name: "FilteresBlock",
  components: {
    VueSlider,
  },
  props: {
    attributeProduct: {
      type: String,
      required: false,
    },
    attributes: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      priceRange: ref([0, 100]),
      dotStyle: {
        backgroundColor: "black",
        border: "2px solid black",
      },
      railStyle: {
        backgroundColor: "#D9D9D9",
        height: "2px",
      },
      processStyle: {
        backgroundColor: "#D9D9D9",
        height: "2px",
      },
      dotOptions: [
        {
          style: {
            backgroundColor: "black",
            border: "2px solid black",
            width: "18px",
            height: "18px",
            borderRadius: "50%",
          },
        },
        {
          style: {
            backgroundColor: "black",
            border: "2px solid black",
            width: "18px",
            height: "18px",
            borderRadius: "50%",
          },
        },
        {
          style: {
            backgroundColor: "black",
            border: "2px solid black",
            width: "18px",
            height: "18px",
            borderRadius: "50%",
          },
        },
        { tooltip: false }, // Первый бегунок
        { tooltip: false }, // Второй бегунок
      ],
      isOpen: false,
    };
  },
  methods: {
    toogleFilter() {
      this.isOpen = !this.isOpen;
    },
    beforeEnter: function (el) {
      el.style.height = "0";
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function (el) {
      el.style.height = "0";
    },
  },
  mounted() {
    
  },
};
</script>

<template>
  <div class="filteres-block" v-if="attributeProduct === 'price'">
    <div class="name__filter" @click="toogleFilter">
      Цена
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
      >
        <path
          d="M12.8926 1.5L7.00002 7.39256L1.10746 1.5"
          stroke="#111111"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <transition
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div class="filterPrice" v-show="isOpen">
        <vue-slider
          class="filterPrice__slider"
          v-model="priceRange"
          :dot-style="dotStyle"
          :rail-style="railStyle"
          :process-style="processStyle"
          :dot-options="dotOptions"
          :dot-tooltips="false"
        ></vue-slider>
        <div class="inputs">
          <input
            class="input-price"
            v-model="priceRange[0]"
            type="text"
            placeholder="от"
          />
          <input
            class="input-price"
            v-model="priceRange[1]"
            type="text"
            placeholder="до"
          />
        </div>
      </div>
    </transition>
  </div>
  <div class="filteres-block" v-else-if="attributeProduct !== 'price'">
    <div class="name__filter" @click="toogleFilter">
      {{ attributes.attrGroup }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
      >
        <path
          d="M12.8926 1.5L7.00002 7.39256L1.10746 1.5"
          stroke="#111111"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <transition
      v-for="item in attributes.attributes"
      :key="item.id"
      name="accordion"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div class="filterPrice" v-show="isOpen">
        <input :id="item" class="checkbox" type="checkbox" :value="item" />
        <label class="label" :for="item">
          {{ item }}
        </label>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.filteres-block {
  max-width: 310px;
  margin-top: 20px;
  border-bottom: 1px solid #11111121;
  padding-bottom: 12px;
}
.name__filter {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 122%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.filterPrice {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  transition: all 0.3s ease;
}
.accordion-enter-active,
.accordion-leave-active {
  transition: all 0.3s ease;
}
.accordion-enter,
.accordion-leave-to {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
.checkbox {
  display: none;
}
.label {
  color: var(--Black, #111);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 26px;
  position: relative;
  cursor: pointer;
}
.label::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #11111121;
  border-radius: 2px;
  left: 0;
  top: 0;
  transition: all 0.3s;
}
.label:hover::after {
  border-color: rgba(118, 118, 118, 1);
  transition: all 0.3s;
}
.checkbox:checked + .label::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background: url("../../assets/image/check.svg"),
    linear-gradient(0deg, #111, #111);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px;
  transition: all 0.3s;
}
.inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}
.input-price {
  border-radius: 2px;
  border: 1px solid var(--Black-400, #d9d9d9);
  color: var(--Black-600, #434343);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 14px 12px 10px 12px;
  max-width: calc(50% - 7px);
}
.input-price::placeholder {
  color: var(--Black-600, #b0b0b0);
}
@media screen and (max-width: 1024px) {
  .filteres-block{
    max-width: none;
    width: 100%;
  }
  .input-price{
    width: 100%;
  }
}
</style>