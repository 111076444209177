import router from './router/router'
import '../src/assets/css/style.css';
import { createApp } from 'vue'
import App from './App.vue'

import store from "@/store";

createApp(App).use(store).use(router).mount('#app')

document.addEventListener("DOMContentLoaded", function () {
    let soon = document.querySelectorAll(".soon");

soon.forEach((item) => {
    item.addEventListener("click", (e) => {
        e.preventDefault();
        console.log("soon");
        let notification = document.querySelector(".notification");
        notification.classList.add("active");
    });
});
let close = document.querySelector(".notification button");
close.addEventListener("click", () => {
    let notification = document.querySelector(".notification");
    notification.classList.remove("active");
});
})
