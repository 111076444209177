<script>
import HeaderBlock from "../block/HeaderBlock.vue";
import FooterBlock from "../block/FooterBlock.vue";
import ShopMainBlock from "../block/ShopMainBlock.vue";
import shopItemsBlock from "../block/shopItemsBlock.vue";
import FilteresBlock from "../block/FilteresBlock.vue";
import axios from "axios";

export default {
  name: "ShopPage",
  components: {
    HeaderBlock,
    FooterBlock,
    ShopMainBlock,
    shopItemsBlock,
    FilteresBlock,
  },
  props: {
    categoryId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: false,
      total: 0,
      products: [],
      baseProducts: [],
      filteres: [],
      allAtributes: [],
      totalPages: 1,
      currentPage: 1,
      banner: "",
      user: this.$store.getters.getUser,
      apiUrl: this.$store.getters.getApiUrl,
      apiDomain: this.$store.getters.getApiDomain,
      obj: {
        attrGroup: "Цветовая температура",
        atributes: ["4000 К", "5000 К", "6000 К", "7000 К", "8000 К", "9000 К"],
      },
    };
  },
  methods: {
    getProducts() {
      let params = `&auth=${this.user.username}:${this.user.auth_key}`;
      if (this.categoryId != 0) {
        params += `&group_id=${this.categoryId}`;
        axios
          .get(`${this.apiUrl}api/product/get-product-by-category${params}`)
          .then((response) => {
            this.products = response.data.products;
            this.baseProducts = response.data.products;
            this.filteredProducts = this.products;
          });
      } else {
        axios
          .get(`${this.apiUrl}api/product/get-product${params}`)
          .then((response) => {
            this.products = response.data.products;
            this.baseProducts = response.data.products;
            this.totalPages = Math.ceil(response.data.total / 2);
            this.filteredProducts = this.products;
            this.filteres = response.data.attributes;
            this.allAtributes = this.groupedAttributes();
            this.total = response.data.total;
            console.log("RESPONSE", response.data);
          });
      }
    },
    toggleSort() {
      document.querySelector(".sort__rating").classList.toggle("active");
      document.querySelector(".sort__text").classList.toggle("active");
    },
    sortItem() {
      let items = document.querySelectorAll(".sort__rating-item");

      items.forEach((item) => {
        item.addEventListener("click", () => {
          let sort = item.getAttribute("data-sort");
          console.log(sort);
          if (sort === "default") {
            document.querySelector(".sort__text span").innerHTML = "Новинки";
          }
          if (sort === "priceMin") {
            document.querySelector(".sort__text span").innerHTML =
              "По убыванию цены";
          }
          if (sort === "priceMax") {
            document.querySelector(".sort__text span").innerHTML =
              "По возрастанию цены";
          }
        });
      });
    },
    groupAttributesByGroup(attributes) {
      return attributes.reduce((result, current) => {
        let group = result.find((item) => item.attrGroup === current.attrGroup);

        if (group) {
          if (!group.attributes.includes(current.attr)) {
            group.attributes.push(current.attr);
          }
        } else {
          result.push({
            attrGroup: current.attrGroup,
            attributes: [current.attr],
          });
        }

        return result;
      }, []);
    },
    groupedAttributes() {
      return this.groupAttributesByGroup(this.filteres);
    },
    getTotalEnding(number) {
      const remainder10 = number % 10;
      const remainder100 = number % 100;

      if (remainder10 === 1 && remainder100 !== 11) {
        return "товар";
      } else if (
        remainder10 >= 2 &&
        remainder10 <= 4 &&
        (remainder100 < 12 || remainder100 > 14)
      ) {
        return "товара";
      } else {
        return "товаров";
      }
    },
    openFilteres() {
      document.querySelector(".filteres__mobile").classList.toggle("active");
    },
    closeFilteres(element) {
      document.querySelector(".filteres__mobile").classList.remove("active");
      if (element) {
        document.querySelector('#shop').scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  computed: {
    totalText() {
      return `${this.total} ${this.getTotalEnding(this.total)}`;
    },
  },
  mounted() {
    this.sortItem();
    this.getProducts();
  },
};
</script>

<template>
  <HeaderBlock :className="'white'"></HeaderBlock>

  <ShopMainBlock></ShopMainBlock>
  <div class="shop" id="shop">
    <div class="container">
      <div class="shop__head">
        <h1 class="title">Каталог</h1>
        <div class="link__filteres">
          <a @click="openFilteres" class="link__filteres--mobile">
            Фильтры
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M3.33337 17.5001V11.6667"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.33337 8.33333V2.5"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 17.5V10"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 6.66667V2.5"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6666 17.4999V13.3333"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6666 10V2.5"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M0.833374 11.6667H5.83337"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.5 6.66675H12.5"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.1666 13.3333H19.1666"
                stroke="#111111"
                stroke-width="1.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
          <div class="products__sort-rating" @click="toggleSort">
            <div class="sort__text">
              <span>Популярные</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.5 9.61471L12.9575 5.15717C13.0911 5.02814 13.2701 4.95674 13.4558 4.95836C13.6415 4.95997 13.8192 5.03447 13.9505 5.1658C14.0818 5.29713 14.1563 5.47479 14.1579 5.66051C14.1595 5.84623 14.0882 6.02516 13.9591 6.15875L9.00079 11.1171C8.93506 11.1829 8.857 11.2352 8.77107 11.2708C8.68514 11.3064 8.59302 11.3248 8.5 11.3248C8.40697 11.3248 8.31485 11.3064 8.22892 11.2708C8.14299 11.2352 8.06493 11.1829 7.9992 11.1171L3.04087 6.15875C2.91184 6.02516 2.84045 5.84623 2.84206 5.66051C2.84367 5.47478 2.91817 5.29713 3.0495 5.16579C3.18083 5.03446 3.35849 4.95997 3.54421 4.95836C3.72994 4.95674 3.90886 5.02814 4.04246 5.15717L8.5 9.61471Z"
                  fill="black"
                />
              </svg>
            </div>
            <div class="sort__rating">
              <div class="sort__rating-item" data-sort="default">Новинки</div>
              <div class="sort__rating-item" data-sort="priceMin">
                По убыванию цены
              </div>
              <div class="sort__rating-item" data-sort="priceMax">
                По возрастанию цены
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shop__content">
        <div class="filteres__desktop">
          <FilteresBlock :attributeProduct="'price'"></FilteresBlock>
          <FilteresBlock
            v-for="attribute in allAtributes"
            :key="attribute.id"
            :attributes="attribute"
            :attributeProduct="'color'"
          ></FilteresBlock>
          <a class="sbros">Сбросить все</a>
        </div>
        <div class="filteres__mobile">
          <div class="filteres__head">
            <div class="filteres__title">Фильтры</div>
            <a @click="closeFilteres" class="filteres__close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18M6 6L18 18"
                  stroke="#545454"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div class="filteres__body">
            <FilteresBlock :attributeProduct="'price'"></FilteresBlock>
            <FilteresBlock
              v-for="attribute in allAtributes"
              :key="attribute.id"
              :attributes="attribute"
              :attributeProduct="'color'"
            ></FilteresBlock>
          </div>
          <div class="btns__filteres">
            <a @click="closeFilteres('scroll')" class="modificationBtn">Показать товары</a>
            <a class="sbros">Сбросить все</a>
          </div>
        </div>
        <shopItemsBlock :products="products"></shopItemsBlock>
        <div class="total">
          <p>{{ totalText }}</p>
        </div>
      </div>
    </div>
  </div>
  <FooterBlock></FooterBlock>
</template>

<style scoped>
.shop__head {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 60px;
}

.filteres__desktop {
  width: 310px;
  flex-shrink: 0;
}
.shop__content {
  display: flex;
  justify-content: space-between;
  gap: 75px;
  margin-top: 56px;
  position: relative;
}
.total {
  position: absolute;
  top: -24px;
  right: 0;
  margin-top: 0;
  margin-right: 0;
  font-family: "Suisse Intl";
  width: max-content;
  color: var(--Black-700, #a1a1a1);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.filteres__mobile {
  display: none;
}
.link__filteres--mobile {
  display: none;
}
.link__filteres {
  display: flex;
  align-items: center;
  gap: 28px;
}
.sbros {
  color: var(--Black-900, #757575);
  font-family: "Suisse Intl";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media screen and (max-width: 1440px) {
  .filteres__desktop {
    width: 255px;
  }
  .shop__content {
    gap: 70px;
  }
}
@media screen and (max-width: 1024px) {
  .filteres__desktop {
    display: none;
  }
  .filteres__mobile {
    display: flex;
    flex-direction: column;
    gap: 36px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 5;
    padding: 28px 24px 139px;
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  .filteres__mobile.active {
    opacity: 1;
    visibility: visible;
    transition: all 0.3s;
  }
  body:has(.filteres__mobile.active) {
    overflow: hidden;
  }
  .filteres__mobile::-webkit-scrollbar {
    width: 0;
  }
  .filteres__title {
    color: var(--Black, #111);
    font-family: "Suisse Intl";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .filteres__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link__filteres--mobile {
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--Black, #111);
    font-family: "Suisse Intl";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 122%;
  }
  .btns__filteres{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 12px 24px 18px;
    background: #fff;
  }
}
@media screen and (max-width: 650px) {
  .title{
    width: 100%;
    flex-shrink: 0;
    
  }
  .shop__head{
    flex-wrap: wrap;
    row-gap: 39px;
  }
  .shop__content{
    margin-top: 25px;
  }
  .total{
    left: 0;
    right: auto;
    top: -74px;
  }
  
}
</style>