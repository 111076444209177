<script>
import FooterBlock from "../block/FooterBlock.vue";

import AboutMainBlockVue from '../block/AboutMainBlock.vue';

export default {
  name: "AboutPage",
  components: {
    
    FooterBlock,
   
    AboutMainBlockVue
  },
};
</script>

<template>
  <div class="about">
    

    <AboutMainBlockVue></AboutMainBlockVue>

    <FooterBlock></FooterBlock>
  </div>
</template>

<style scoped>
.about {
  background: var(--Black, #111);
}
 *{
    transition: all 0;
}
.header {
  background: var(--Black-1300, #1d1d1b);
  backdrop-filter: blur(17px);
}
</style>